<template>
  <a-spin :spinning="spinning">
    <div class="">
      <!-- <div>
        <a-menu
          mode="horizontal"
          v-model="searchFormInline.Status"
          @select="StatusChange"
        >
          <a-menu-item key="Process"><a-icon type="api" />进行中</a-menu-item>
          <a-menu-item key="Finish">
            <a-icon type="file-done" />已完成
          </a-menu-item>
          <a-menu-item key="Down">
            <a-icon type="down-square" />已下架
          </a-menu-item>
        </a-menu>
      </div> -->
      <div class="tk_table">
        <!-- <react-table
          :table-data="tableData"
          :total-items="totalItems"
          :showTotal="showTotalFun"
          :actions-type="actionsType"
          :scroll="{ x: 1000 }"
          :columns="columns"
          :current-page="pageNumber"
          :is-full="true"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSortData="updateSortData"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        > -->
        <ele-table ref="rt" :columns="columns" :table-data="tableData" :total-items="totalItems" :is-full="true"
          :actionsType="actionsType" :current-page="pageNumber" :hide-row-selection="false"
          @emitSelectChange="updateSelectChange" @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange">
          <!-- 按钮 -->
          <a-row :gutter="8">
            <a-col class="gutter-row" :span="18" v-if="false">
              <a-form-model layout="inline" :model="searchFormInline" @submit="getData" @submit.native.prevent>
                <a-form-model-item label="">
                  <a-input v-model="searchFormInline.projectName" placeholder="请选择项目" @click="selectProjectModal">
                  </a-input>
                  <a-input v-model="searchFormInline.projectId" type="hidden">
                  </a-input>
                </a-form-model-item>
                <a-form-model-item label="">
                  <a-input v-model.trim="searchFormInline.TaskName" :placeholder="l('任务名称')" />
                </a-form-model-item>
                <a-form-model-item label="">
                  <a-input v-model.trim="searchFormInline.TaskCode" :placeholder="l('任务编码')" />
                </a-form-model-item>
                <a-form-model-item>
                  <a-button type="primary" @click="getData" v-if="isGranted('Pages.BillPermissions.Query')">
                    <a-icon type="search" /> {{ l("搜索") }}
                  </a-button>
                  <a-button @click="clearFilterAndRefresh">
                    {{ l("重置") }}
                  </a-button>
                </a-form-model-item>
              </a-form-model>
            </a-col>

            <a-col :span="3">
              <a-select style="width: 100%" placeholder="请选择" v-model="searchFormInline.Status" @change="StatusChange">
                <a-select-option :value="it.value" v-for="(it, i) in statusOpts" :key="i">
                  {{ it.label }}
                </a-select-option>
              </a-select>
            </a-col>

            <a-col :span="3">
              <a-input v-model="searchFormInline.projectName" placeholder="请选择项目" @click="selectProjectModal">
              </a-input>
              <a-input v-model="searchFormInline.projectId" type="hidden">
              </a-input>
            </a-col>

            <a-col :span="3">
              <a-input v-model="searchFormInline.TaskName" :placeholder="l('任务名称/任务编码')" />
            </a-col>

            <!-- <a-col :span="4">
              <a-input
                v-model="searchFormInline.TaskCode"
                :placeholder="l('任务编码')"
              />
            </a-col> -->
            <a-col class="gutter-row" :span="3">
              <a-select v-model="CustomerId" style="width: 100%" show-search :filter-option="filterOption"
                :placeholder="l('PleaseSelectCustomer')" allowClear>
                <a-select-option v-for="item in Customers" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-range-picker :placeholder="[l('起始日期'), l('结束日期')]" v-model="startToEndDate" />
            </a-col>
            <a-col :span="4">
              <a-button type="primary" @click="getData" v-if="isGranted('Pages.BillPermissions.Query')">
                <a-icon type="search" /> {{ l("搜索") }}
              </a-button>
              <a-button @click="clearFilterAndRefresh">
                {{ l("重置") }}
              </a-button>
            </a-col>


          </a-row>
          <a-row :gutter="8" style="margin-top:10px">
            <a-col class="gutter-row" align="right" :span="24">
              <a-button-group>
                <a-button v-if="isGranted('Pages.LGModul.Task.BatchCreateTaskPersonnel')"
                  @click="importCreateTaskPersonnel()">
                  <a-icon type="plus" />
                  批量创建派发人员任务
                </a-button>
                <a-button type="primary" @click="createOrEditNew()" v-if="isGranted('Pages.LGModul.Task.CreateNew')">
                  <a-icon type="plus" />
                  添加
                </a-button>
                <!-- <a-button
                  type="primary"
                  @click="createOrEdit()"
                  v-if="isGranted('Pages.LGModul.Task.Create')"
                >
                  <a-icon type="plus" />
                  {{ l("Create") }}
                </a-button> -->
                <a-button :disabled="selectedRows.length === 0" type="danger" ghost @click="TaskDown()"
                  v-if="isGranted('Pages.LGModule.Task.TaskDown')">
                  <a-icon type="down-square" />
                  {{ l("TaskActionDown") }}
                </a-button>
              </a-button-group>
            </a-col>
          </a-row>
        </ele-table>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import TaskForm from "./action/addorupdate.vue";
import TaskFormNew from "./action/addorupdate-new-1.vue";
import DetailForm from "./action/details.vue";
import axios from "axios";
import EleTable from "@/components/ele-table";
import addTaskPersonnel from "./action/addtaskpersonnel.vue";
import getDispatchPersonnel from "./action/getDispatchpersonnel.vue";
import ImportForm from "./importform.vue";
import ImportFormNew from "./importform-new-1.vue";
import ImportFormMax from "./importform-new-2.vue";
import SelectPageList from "@/components/linggong/select-page-list";
import moment from "moment";
import ImportCreateTaskPersonnelForm from "./action/import-createtaskpersonnel-form.vue";

export default {
  mixins: [AppComponentBase],
  name: "TaskIndex",
  components: { EleTable },
  data: function () {
    return {
      filterText: "",
      spinning: false,
      windowHeight: 0,
      ProjectData: [],
      searchFormInline: {
        // Status: ["Process"],
        Status: "Process",
        projectId: undefined,
        projectName: "",
        TaskName: "",
        TaskCode: "",
        Sorting: "",
        MaxResultCount: 50,
        SkipCount: 0,
      },
      tableData: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      selectedRows: [],
      pageSizeOptions: ["10", "20", "30", "40"],
      columns: [],
      statusOpts: [
        { label: "进行中", value: "Process" },
        { label: "已完成", value: "Finish" },
        { label: "已下架", value: "Down" },
      ],
      startToEndDate: [],
      Customers: [],
      CustomerId: undefined,
    };
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    selectProjectModal () {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }
          this.searchFormInline.projectId = projectIds;
          this.searchFormInline.projectName = projectNames;
        }
      });
    },
    initColumns () {
      let _this = this;
      this.columns = [
        {
          title: this.l("TaskHeaderCode"),
          dataIndex: "taskCode",
          sorter: false,
          // ellipsis: true,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "taskCode" },
        },
        {
          title: this.l("TaskHeaderName"),
          dataIndex: "taskTitle",
          sorter: false,
          align: "center",
          // width: 230,
          ellipsis: true,
          scopedSlots: { customRender: "taskTitle" },
        },
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          sorter: false,
          align: "center",
          width: 220,
          ellipsis: true,
          scopedSlots: { customRender: "projectName" },
        },
        // {
        //   title: this.l("TaskDisplayCompanyName"),
        //   dataIndex: "displayCompanyName",
        //   sorter: false,
        //   align: "center",
        //   // width: 230,
        //   ellipsis: true,
        //   scopedSlots: { customRender: "displayCompanyName" },
        // },
        {
          title: this.l("TaskCustomerName"),
          dataIndex: "customerName",
          sorter: false,
          align: "center",
          width: 220,
          ellipsis: true,
          scopedSlots: { customRender: "customerName" },
        },
        {
          title: this.l("TaskHeaderSalary"),
          dataIndex: "price",
          sorter: false,
          align: "center",
          width: 100,
          scopedSlots: { customRender: "price" },
          customRender: function (text, record, index) {
            var txt = text.toString() + "元/";
            txt += record.denominated;
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: txt,
              },
              attrs: { title: txt },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
        },
        {
          title: this.l("TaskHeaderNeeds"),
          dataIndex: "numberOfNeed",
          sorter: false,
          align: "center",
          width: 90,
          scopedSlots: { customRender: "numberOfNeed" },
        },
        {
          title: this.l("TaskHeaderTime"),
          dataIndex: "startTime",
          sorter: true,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "startTime" },
          customRender: function (text, record, index) {
            var txt = text.toString().substring(0, 10) + " / ";
            txt += record.endTime.toString().substring(0, 10);
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: txt,
              },
              attrs: { title: txt },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          fixed: "right",
          // width: 130,
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    openImportForm (id) {
      ModalHelper.create(
        ImportForm,
        { taskId: id },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        this.refreshGoFirstPage();
      });
    },
    openImportFormNew (id) {
      ModalHelper.create(
        ImportFormNew,
        { taskId: id },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        this.refreshGoFirstPage();
      });
    },
    openImportFormMax (id) {
      ModalHelper.create(
        ImportFormMax,
        { taskId: id },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        this.refreshGoFirstPage();
      });
    },
    initActionsType () {
      let _this = this;
      let obj = {
        type: "project-user",
        isShow: true,
        fns: {
          detailList: [
            {
              granted: this.isGranted("Pages.LGModule.Task.TaskDetail"),
              name: this.l("任务详情"),
              icon: "profile",
              fn: (data) => {
                _this._taskDetail(data.id);
              },
            },
          ],
          dropList: [
            {
              granted: this.isGranted(
                "Pages.LGModule.Task.ImportTaskExecuteRecord"
              ),
              name: this.l("导入执行记录"),
              icon: "plus",
              fn: (data) => {
                _this.openImportForm(data.id);
              },
            },
            {
              granted: this.isGranted(
                "Pages.LGModule.Task.ImportTaskExecuteRecordNew"
              ),
              name: this.l("导入执行记录New"),
              icon: "plus",
              fn: (data) => {
                if (data.isWithdrawal) {
                  _this.$message.info("提现项目不支持导入执行记录!");
                } else {
                  _this.openImportFormNew(data.id);
                }
              },
            },
            {
              granted: this.isGranted(
                "Pages.LGModule.Task.ImportTaskExecuteRecordIgnoreWithdrawal"
              ),
              name: this.l("导入执行记录Max"),
              icon: "plus",
              fn: (data) => {
                _this.openImportFormMax(data.id);
              },
            },
            {
              granted: this.isGranted("Pages.LGModule.Task.AddTaskPersonal"),
              name: this.l("添加任务人员"),
              icon: "plus",
              fn: (data) => {
                _this._addTaskPersonnel(data.id);
              },
            },
            {
              granted: this.isGranted(
                "Pages.LGModule.Dispatch.AddTaskPersonal"
              ),
              name: this.l("添加派单人员"),
              icon: "plus",
              fn: (data) => {
                _this._getDispatchPersonnel(data.id);
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Task.Edit"),
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data.id);
              },
            },
            {
              delete: true,
              // granted: this.isGranted("Pages.LGModul.Task.Delete"),
              granted: false,
              name: this.l("Delete"),
              fn: (data) => {
                _this.deleteItem(data.id);
              },
            },
          ],
          // delete: {
          //   granted: this.isGranted("Pages.LGModul.Task.Delete"),
          //   name: this.l("Delete"),
          //   fn: (data) => {
          //     _this.deleteItem(data.id);
          //   },
          // },
        },
      };
      this.actionsType = obj;
    },
    deleteItem (id) {
      let options = {
        url: this.$apiUrl + "/api/services/app/Task/Delete",
        method: "POST",
        data: { id: id },
        headers: { "Content-Type": "application/json-patch+json" },
      };
      this.$api.request(options).then((res) => {
        console.info(res);
        if (res.status == 200) {
          abp.message.success(this.l("SuccessfullyDeleted"));
          this.getData();
        } else {
          abp.message.error(res.statusText);
        }
      });
    },
    TaskDown () {
      if (this.selectedRows.length <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return false;
      }
      var keys = [];
      for (let i = 0; i < this.selectedRows.length; i++) {
        keys.push(this.selectedRows[i].id);
      }

      let options = {
        url: this.$apiUrl + "/api/services/app/Task/PutDown",
        method: "POST",
        data: keys,
        headers: { "Content-Type": "application/json-patch+json" },
      };
      this.$api.request(options).then((res) => {
        if (res.status == 200) {
          abp.message.success(this.l("TaskActionDown") + this.l("Success"));
          this.getData();
        } else {
          abp.message.error(res.statusText);
        }
      });
    },
    titleClick (e) {
      console.log("titleClick", e);
    },
    showTotalFun () {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 清除条件并刷新
    clearFilterAndRefresh () {
      this.refreshGoFirstPage();
    },
    // 排序更新data
    updateSortData (newV) {
      let { columnKey, order } = newV;
      this.searchFormInline.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // table选择事件
    updateSelectChange (newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onSelectChange (keys, rows) {
      this.selectedRows.splice(0, this.selectedRows.length);
      for (let i = 0; i < rows.length; i++) {
        this.selectedRows.push(rows[i]);
      }
    },
    // 分页
    updatePageChange (newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    refreshGoFirstPage () {
      this.searchFormInline.TaskName = "";
      this.searchFormInline.TaskCode = "";
      this.searchFormInline.projectId = "";
      this.searchFormInline.projectName = "";
      this.searchFormInline.sorting = "";
      this.pageNumber = 1;
      this.searchFormInline.SkipCount = 0;
      this.startToEndDate = [];
      this.CustomerId = undefined;
      this.getData();
    },
    updateShowSizeChange (newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange (current, size) {
      this.pageNumber = 1;
      this.searchFormInline.MaxResultCount = size;
      this.getData();
    },
    onChange (page, pageSize) {
      console.log(page, pageSize);
      this.pageNumber = page;
      this.searchFormInline.SkipCount =
        (page - 1) * this.searchFormInline.MaxResultCount;
      this.getData();
    },
    getData () {
      //if (this.searchFormInline.projectId == '') {
      //    return false;
      //}
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      })
      var startDate = !this.startToEndDate[0] ? undefined : this.startToEndDate[0].format('YYYY-MM-DD');
      var endDate = !this.startToEndDate[1] ? undefined : this.startToEndDate[1].format('YYYY-MM-DD');
      let url = this.$apiUrl + "/api/services/app/Task/GetList";
      let data = {
        projectIds: this.searchFormInline.projectId,
        taskTitle: this.searchFormInline.TaskName,
        taskCode: this.searchFormInline.TaskCode,
        taskStatus: this.getTaskStatus(this.searchFormInline.Status),
        sorting: this.searchFormInline.sorting,
        maxResultCount: this.searchFormInline.MaxResultCount,
        skipCount: this.searchFormInline.SkipCount,
        startDate: startDate,
        endDate: endDate,
        customerId: this.CustomerId,
      };

      this.$api
        .request({
          url: url,
          method: "POST",
          data: data,
          headers: { "Content-Type": "application/json-patch+json" },
        })
        .then((response) => {
          if (response.status == 200) {
            let res = response.data;
            this.totalItems = res.totalCount;
            this.totalPages = Math.ceil(
              res.totalCount / this.searchFormInline.MaxResultCount
            );
            this.pagerange = [
              (this.pageNumber - 1) * this.searchFormInline.MaxResultCount + 1,
              this.pageNumber * this.searchFormInline.MaxResultCount,
            ];
            // this.tableData.splice(0, this.tableData.length);
            this.tableData = res.items.map(item => {
              return {
                ...item,
                startTime: `${moment(item.startTime).format("YYYY-MM-DD")}~${moment(item.endTime).format("YYYY-MM-DD")}`
              }
            });
            console.log(this.tableData)
            // for (let i = 0; i < list.length; i++) {
            //   this.tableData.push(list[i]);
            // }
          }
          this.spinning = false;
        })
        .catch((e) => {
          this.spinning = false;
          abp.message.warn(this.l("DefaultErrorMessage"));
        });
    },
    createOrEdit (id) {
      ModalHelper.create(
        TaskForm,
        { id: id },
        {
          isChange: true,
          width: "800px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    createOrEditNew (id) {
      ModalHelper.create(
        TaskFormNew,
        { id: id },
        {
          isChange: true,
          width: "850px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    importCreateTaskPersonnel () {
      ModalHelper.create(
        ImportCreateTaskPersonnelForm,
        {},
        {
          isChange: true,
          width: "850px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    _taskDetail (id) {
      ModalHelper.create(
        DetailForm,
        { id: id },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    _addTaskPersonnel (id) {
      ModalHelper.create(
        addTaskPersonnel,
        { taskId: id },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    _getDispatchPersonnel (id) {
      ModalHelper.create(
        getDispatchPersonnel,
        { taskId: id },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    getProjectData () {
      let url = this.$apiUrl + "/api/services/app/PersonnelTask/GetProjectList";
      this.$api
        .request({
          url: url,
          method: "GET",
          headers: { "Content-Type": "application/json-patch+json" },
        })
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.ProjectData.splice(0, this.ProjectData.length);
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              this.ProjectData.push({ id: item.id, name: item.projectName });
            }
          } else {
            abp.message.warn(res.statusText);
          }
        })
        .catch((e) => {
          abp.message.warn(this.l("DefaultErrorMessage"));
        });
    },
    getTaskStatus (status) {
      switch (status) {
        case "Process":
          return 0;
        case "Finish":
          return 1;
        default:
          return 2;
      }
    },
    StatusChange (keys) {
      // this.searchFormInline.Status.splice(
      //   0,
      //   this.searchFormInline.Status.length
      // );
      // this.searchFormInline.Status.push(keys.key);
      this.searchFormInline.Status = keys;
      this.getData();
    },
    getAllCutomer () {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
                // console.log(this.Customers)
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  created () {
    this.windowHeight = window.innerHeight - 205;
    this.getData();
    this.getAllCutomer();
    this.initActionsType();
    this.getProjectData();
    this.initColumns();
  },
};
</script>
<style>
.tk_container {
  width: 98%;
  margin: 0 auto;
  background-color: #ccc;
}

.tk_common {
  padding: 0 5px 0 5px;
  background-color: white;
}

.tk_border {
  border: 1px solid #ccc;
}

.tk_body {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.tk_left_top {
  margin: 7px 3px 0px 5px;
  padding-left: 5px;
}

.tk_commonline {
  height: 32px;
  line-height: 32px;
}

.tk_commonline>i {
  /* color: #52c41a; */
  font-size: 18px;
}

.tk_commonline>strong {
  font-size: 16px;
}

.tk_left_item {
  margin: 0px 3px 10px 5px;
  padding-top: 0;
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
  overflow-y: scroll;
}

.tk_left_menu {
  border: 0 !important;
  width: 98%;
}

.tk_list {
  margin: 7px 3px 0px 5px;
}
</style>
