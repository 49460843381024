//import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { FileDto, SwaggerException } from "@/shared/service-proxies/model";
import apiHttpClient from '@/shared/utils/api-http-client';
import { AppConsts } from '@/abpPro/AppConsts';
import { throwError } from "rxjs";
import { UrlHelper } from "../../../helpers";
import { message } from "ant-design-vue";
import { formatInternalValue } from "ant-design-vue/lib/vc-tree-select/src/util";
import objectHelper from "../../../helpers/objectHelper";

let taskApi = {
    instance: apiHttpClient,
    namespaces: "task",
    locked: false,
    localStore: {},
    getInstance: function () {
        return this.instance = this.instance ? this.instance : axios.create()
    },
    getById: async (itemId) => {
    },
    getByCode: async (code) => {
    },
    async importExecutedRecord(taskId, files) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/ImportTaskExecutedRecord";
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: {
                taskId: taskId,
                files: files
            }
        };
        try {
            const _response = await this.getInstance().request(options_);
            console.log(_response)
            if (_response.status != 200) {
                console.log('请求异常')
                console.log(_response)
                throwError(_response.data)
                //todo toast
            }
            return _response.data
        } catch (error) {
            return error.response.data
        }
    },
    async importExecutedRecordNew(taskId, files) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/ImportRecord?taskId=" + taskId;
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: {
                taskId: taskId,
                files: files
            }
        };
        try {
            const _response = await this.getInstance().request(options_);
            console.log(_response)
            if (_response.status != 200) {
                console.log('请求异常')
                console.log(_response)
                throwError(_response.data)
                //todo toast
            }
            return _response.data
        } catch (error) {
            return error.response.data
        }
    },
    async save(item) {
        console.log('???????')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/CreateOrUpdate";
        var content = objectHelper.deepClone(item)
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: content
        };
        try {
            const _response = await this.getInstance().request(options_);
            console.log(_response)
            if (_response.status != 200) {
                console.log('请求异常')
                console.log(_response)
                throwError(_response.data)
                //todo toast
            }
            return _response.data
        } catch (error) {
            return error.response.data
        }
    },
    async createTask(item) {
        console.log('???????')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/CreateTask";
        var content = objectHelper.deepClone(item)
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/x-www-form-urlencoded,multipart/form-data",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            data: item
            // headers: {
            //     Accept: "application/json",
            //     "Content-Type": "application/json-patch+json",
            // },
            // data: content
        };
        try {
            const _response = await this.getInstance().request(options_);
            console.log(_response)
            if (_response.status != 200) {
                console.log('请求异常')
                console.log(_response)
                throwError(_response.data)
                //todo toast
            }
            return _response.data
        } catch (error) {
            return error.response.data
        }
    },
    async batchCreateTaskPersonnel(item) {
        console.log('批量创建派发人员任务')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/BatchCreateTaskPersonnel";
        var content = objectHelper.deepClone(item)
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/x-www-form-urlencoded,multipart/form-data",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            data: item
            // headers: {
            //     Accept: "application/json",
            //     "Content-Type": "application/json-patch+json",
            // },
            // data: content
        };
        try {
            const _response = await this.getInstance().request(options_);
            console.log(_response)
            if (_response.status != 200) {
                console.log('请求异常')
                console.log(_response)
                throwError(_response.data)
                //todo toast
            }
            return _response.data
        } catch (error) {
            return error.response.data
        }
    },
    async importTaskExecutedRecordNew(data, url) {
        let url_ = AppConsts.remoteServiceBaseUrl + url;
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/x-www-form-urlencoded,multipart/form-data",
            },
            data: data
        };
        try {
            const _response = await this.getInstance().request(options_);
            console.log(_response)
            if (_response.status != 200) {
                console.log('请求异常')
                console.log(_response)
                throwError(_response.data)
            }
            return _response.data
        } catch (error) {
            return error.response.data
        }
    }
}

export default taskApi