 import {AppConsts} from "../../abpPro/AppConsts";

 export default   {
     downloadTempFile(file) {
    const url = `${AppConsts.remoteServiceBaseUrl}/File/DownloadTempFile?fileType=${file.FileType}
    &fileToken=${file.FileToken}&fileName=${file.FileName}`;
    location.href = url; // TODO: 这将导致在Firefox中重新加载相同的页面,需要等待修复
 },
     downloadTemplateFile(fileName){
         const url = `${AppConsts.remoteServiceBaseUrl}/yoyosoft/SampleFiles/${fileName}`;
         location.href = url; // TODO: 这将导致在Firefox中重新加载相同的页面,需要等待修复
     }

}
