<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>执行记录导入</span>
      </div>
    </div>
    <a-row :gutter="16" class="btn--container">
      <a-col class="gutter-row" :span="12" style="color:red;font-size:20px;">
        <!-- <a-button type="primary" @click="testUpload()">
          <a-icon type="download" />
          {{ l("上传文件") }}
        </a-button> -->
        温馨提示：先下载模板再导入执行记录
      </a-col>
      <a-col class="gutter-row" :span="12" style="text-align: right">
        <a-button  @click="downLoad()">
          <a-icon type="download" />
          {{ l("下载模板") }}
        </a-button>
      </a-col>
    </a-row>
    <a-row :gutter="16" class="upload--container">
      <a-col class="gutter-row" :span="24">
        <a-upload-dragger
          ref = "uploadExcel"
          name="file"
          :multiple="false"
          :action="uploadUrl"
          accept=".xls,.xlsx"
          :headers="uploadHeaders"
          :before-upload="beforeUpload"
          :showUploadList="showUploadList"
          @change="handleChange"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">将文件拖到此处或点击上传</p>
          <p class="ant-upload-hint">只支持.xls,.xlsx文件格式上传。</p>
        </a-upload-dragger>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { setInterval, setTimeout } from "timers";
import { AppConsts } from "@/abpPro/AppConsts";
import fileHelper from "../../../shared/helpers/fileHelper";
import taskApi from "../../../shared/service-proxies/task/services/task-proxies";

import { fileDownloadService } from "@/shared/utils";

export default {
  mixins: [ModalComponentBase],
  name: "task-executed-record-import-form",
  data() {
    return {
      taskId:'',
      spinning: false,
      fileList: [],
      uploadHeaders: {},
      uploadUrl: "",
      showUploadList: false,
    };
  },
  components: {},
  beforeCreate() {},
  created() {
    Object.assign(this.uploadHeaders, {
      Authorization: "Bearer " + abp.auth.getToken(),
    });
    this.fullData()
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
  },
  mounted() {},
  methods: {
   async  handleChange(info) {
      // console.log('info:', info);

      const status = info.file.status;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        let message = info.file.response;

        let  fileList = []
        //  for(let i in info.fileList){
        let curFile = info.fileList[info.fileList.length -1]
        fileList.push({
          id: curFile.response.data.Id,
          name: curFile.response.data.Name,
          path: AppConsts.uploadBaseUrl + curFile.response.data.Path,
          createTime: curFile.response.data.CreateTime
        })
        //  }


        this.spinning  = true
        let res = await taskApi.importExecutedRecord(this.taskId, fileList)

        this.spinning  =  false

        if (res.fileInfo && res.fileInfo.fileName && res.fileInfo.fileToken && res.fileInfo.fileType) {
          this.$message.warning(res.message);
          fileDownloadService.downloadTempFile(res.fileInfo)
        } else {
          this.$message.success(`${info.file.name}导入成功.`);
          this.showUploadList = true
          this.success(true);
        }

        // if (result.code != '200') {
        //   if (result.error.code == 505) {
        //     let file = JSON.parse(result.error.details)
        //       //  this.file
        //     // console.log(file)
        //     fileHelper.downloadTempFile(file)
        //   }
        // } else {
        //   this.$message.success(`${info.file.name}导入成功.`);
        //   this.showUploadList = true
        //   this.success(true);
        // }
        // console.log(this.dataList);
      } else if (status === "error") {
          let message = info.file.response.error.message;
        this.$message.error(`${info.file.name}文件上传失败.`);
      }
    },
    //限制文件上传大小
    beforeUpload(file) {
      console.log(file);
      var fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isFile = !(fileName != "xlsx" && fileName != "xls");

      if (!isFile) {
        this.$message.error("文件格式必须是xls或xlsx!");
      }
      const isLt10M = file.size / 1024 / 1024 <= 10;
      if (!isLt10M) {
        this.$message.error("上传的文件必须小于 10MB！");
      }
      return isFile && isLt10M;
    },
    //点击下载模板
    downLoad() {
      //   window.location.href = '/门店导入模板.xls'
      fileHelper.downloadTemplateFile("执行记录与发放记录导入模板.xls");
    },
    testUpload(){

    }
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin: 0 20px !important;
}
.upload--container {
  margin: 20px !important;
}
</style>
