<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title" style="width: 100%; text-align: left">
        <span>{{ l("批量创建派发人员任务") }}</span>
      </div>
    </div>
    <a-form :form="form" @submit="handleSubmit" layout="horizontal">
      <div class="formScroll">
        <a-row :gutter="24">
          <a-col span="12">
            <!--所属项目-->
            <a-form-item :label="l('ProjectHeaderName')">
              <a-input :placeholder="l('ProjectHeaderName')" @click="selectProjectModal" v-decorator="[
                'Project',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                    { max: 30, message: l('validation.maxlength') },
                  ],
                },
              ]" />
              <a-input v-decorator="['ProjectId']" type="hidden" />
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!--公司简称-->
            <a-form-item :label="l('CompanyShortName')">
              <a-input :placeholder="l('CompanyShortName')" v-decorator="[
                'DisplayCompanyName',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                    { max: 30, message: l('validation.maxlength') },
                  ],
                },
              ]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--任务模板-->
            <a-form-item :label="l('TaskHeadTemplate')">
              <a-select :placeholder="l('TaskHeadTemplate')" v-decorator="[
                'TemplateId',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" :disabled="id ? true : false">
                <a-select-option v-for="item in TemplateData" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!--计薪方式-->
            <a-form-item :label="l('结算方式')">
              <a-select :placeholder="l('结算方式')" v-decorator="[
                'SalaryType',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]">
                <a-select-option v-for="item in SalaryData" :key="item.key" :value="item.value">{{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--学历要求-->
            <a-form-item :label="l('TaskHeadEdutation')">
              <a-select placeholder="请选择学历要求" v-decorator="['Education', { rules: [] }]" style="width: 100%">
                <a-select-option :value="item.value" v-for="item in StudyData" :key="item.key">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!--经验要求-->
            <a-form-item :label="l('TaskHeadYearNeed')">
              <a-select placeholder="请选择经验要求" v-decorator="[
                'Experience',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" style="width: 100%">
                <a-select-option :value="item.value" v-for="item in ExperienceData" :key="item.key">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--性别要求-->
            <a-form-item :label="l('TaskHeadSexNeed')">
              <a-select placeholder="请选择性别要求" v-decorator="[
                'SexRequired',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" style="width: 100%">
                <a-select-option :value="item.value" v-for="item in SexRequiredData" :key="item.key">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!-- 岗位福利-->
            <a-form-item :label="l('TaskHeadWelfare')">
              <a-select placeholder="请选择岗位福利" mode="multiple" v-decorator="['Welfare', { rules: [] }]"
                style="width: 100%">
                <a-select-option v-for="item in WelfareData" :key="item.id" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--所需人数-->
            <a-form-item :label="l('TaskHeaderNeeds')">
              <a-input-number placeholder="请输入所需人数" v-decorator="[
                'Needs',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" :step="1" :min="1" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!--结算限价-->
            <a-form-item :label="l('结算限价')">
              <span
                style="background-color: rgb(217,217,217);height: 32px;line-height: 32px;padding:0 7px;display: inline-block;margin-right: -2px;border: 1px solid rgb(217,217,217);">最高</span>
              <a-input-number placeholder="请输入最高结算价" v-decorator="[
                'MaxPrice',
                {
                  rules: [],
                },
              ]" :step="0.01" :min="0" style="width: 72%" :precision="2" />
              <span
                style="background-color: rgb(217,217,217);height: 32px;line-height: 32px;padding:0 7px;display: inline-block;margin-right: -2px;border: 1px solid rgb(217,217,217);">元</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--任务是否单次执行-->
            <a-form-item :label="l('任务是否单次执行')">
              <a-switch v-decorator="['isSingleExecution', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="btn--container">
          <a-col class="gutter-row" :span="12" style="color: red; font-size: 20px">
            温馨提示：先下载模板
          </a-col>
          <a-col class="gutter-row" :span="12" style="text-align: right">
            <a-tooltip placement="top">
              <template slot="title">
                <span>下载模板填充后进行导入</span>
              </template>
              <a-button @click="downLoad" style="margin-right: 10px">
                <a-icon type="download" />
                <span>下载模板</span>
              </a-button>
            </a-tooltip>
          </a-col>
        </a-row>

        <a-row :gutter="24" style="margin-top: 10px">
          <a-col>
            <a-upload-dragger ref="uploadExcel" name="file" a-upload-dragger :multiple="false" :action="uploadUrl"
              accept=".xls,.xlsx" :before-upload="beforeUpload" :fileList="fileList" @change="handleChange">
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">将文件拖到此处或点击上传</p>
            </a-upload-dragger>
          </a-col>
        </a-row>
      </div>
      <a-row :gutter="24" style="margin-top: 40px">
        <a-col span="24">
          <a-form-item class="btn--container" style="text-align: center">
            <a-button type="button" @click="close()">
              {{ l("Cancel") }}
            </a-button>
            <a-button :loading="loading" type="primary" html-type="submit">
              {{ l("Save") }}
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { ProjectServiceProxy, ProjectDto } from "@/shared/service-proxies";
import { setInterval, setTimeout } from "timers";
import { AppConsts } from "@/abpPro/AppConsts";
import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
import E from "editor4kg";
import { message } from "ant-design-vue";
import { fileDownloadService } from "@/shared/utils";
import fileHelper from "@/shared/helpers/fileHelper";
import taskApi from "@/shared/service-proxies/task/services/task-proxies";
import workOrderApi from "@/shared/service-proxies/workOrder/services/work-order-proxies";
import SelectPageList from "@/components/linggong/select-page-list";

export default {
  mixins: [ModalComponentBase],
  name: "import-createtaskpersonnel-form",
  data () {
    return {
      workOrderOptions: [],
      // 获取到的数据
      mode: "create",
      entity: {},
      spinning: false,
      TimeInterval: null,
      Proxy: "",
      ClientData: [],
      CompanyData: [],
      ProjectData: [],
      InvoiceData: [],
      ProjectTypeData: [],
      SalaryData: [],
      StudyData: [],
      ExperienceData: [],
      WelfareData: [],
      ProvinceData: [],
      CityData: [],
      ChildrenCityData: [],
      SexRequiredData: [],
      TemplateData: [],
      DynamicCascader: [],
      WorkAddress: [],
      Province: "",
      City: "",
      ChildrenProvince: {},
      ChildrenCity: {},
      editor: null,
      CurrentProvince: null,
      CurrentCity: null,
      fileList: [],
      _fileDownloadService: "",
      uploadHeaders: {},
      uploadUrl: "",
      workOrderId: "",
      workOrderNo: "",
      workOrders: [],
      loading: false,
      importFile: undefined,
    };
  },
  components: {},
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created () {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.SalaryData = dataItemApi.getOptions("SalaryType");
    this.StudyData = dataItemApi.getOptions("Education");
    this.ExperienceData = dataItemApi.getOptions("Years");
    this.WelfareData = dataItemApi.getOptions("Welfare");
    this.SexRequiredData = dataItemApi.getOptions("SexRequired");
    this.getAllProject();
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
  },

  mounted () { },
  methods: {
    selectProjectModal () {
      ModalHelper.create(
        SelectPageList,
        {
          //   selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
          console.log(data);
          this.form.setFieldsValue({
            Project: data.name,
            ProjectId: data.id,
            DisplayCompanyName: data.customerName,
          });

          this.getAllTemplate(data.id, () => { });
          workOrderApi.getTakenList({ projectId: data.id }).then((res) => {
            this.workOrders = res.items;
            this.workOrderOptions = res.items.map((item) => {
              return {
                label: item.woName,
                key: item.id,
                value: item.id,
                title: item.woName,
              };
            });
          });
        }
      });
    },
    /**
     * 提交表单
     */
    async handleSubmit (e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          let welfare = values.Welfare;
          let txt = "";
          if (welfare && welfare.length > 0) {
            for (let i = 0; i < welfare.length; i++) {
              txt += welfare[i] + ",";
            }
            txt = txt.substring(0, txt.length - 1);
          }
          var d = new FormData();
          d.append("projectId", values.ProjectId);
          //   d.append("taskCode", values.TaskCode);
          //   d.append("taskTitle", values.TaskTitle);
          d.append("positionType", "");
          d.append("taskTemplateId", values.TemplateId);
          d.append("denominated", values.SalaryType);
          d.append(
            "education",
            values.Education === undefined ? "" : values.Education
          );
          d.append("sexRequest", values.SexRequired);
          d.append("postWelfare", txt);
          d.append("experienceRequest", values.Experience);
          d.append("numberOfNeed", values.Needs);
          d.append("displayCompanyName", values.DisplayCompanyName);
          d.append(
            "isSingleExecution",
            values.isSingleExecution === undefined ? false : values.isSingleExecution
          );
          if (this.importFile === undefined) {
            this.$message.warning("若要导入派发人员任务，请上传文件");
            return;
          }
          d.append("file", this.importFile);
          d.append("isNeedImportExecuteRecord", true);
          if (values.MaxPrice && values.MaxPrice >= 0) {
            d.append('maxPrice', values.MaxPrice);
          }
          this.loading = true;
          this.spinning = true;
          let res = await taskApi.batchCreateTaskPersonnel(d);
          console.log(res);
          if (
            res.fileInfo &&
            res.fileInfo.fileName &&
            res.fileInfo.fileToken &&
            res.fileInfo.fileType
          ) {
            this.$message.warning(res.message);
            fileDownloadService.downloadTempFile(res.fileInfo);
          } else {
            if (res.success || res.fileInfo === null) {
              this.$message.success("创建成功");
              this.showUploadList = true;
              this.success(true);
            }
          }
          this.loading = false;
          this.spinning = false;

          // if (respData.code != "200") {
          //     this.spinning = false;
          //     if (respData.error.code == 505) {
          //         let file = JSON.parse(respData.error.details);
          //         // console.log(file);
          //         fileHelper.downloadTempFile(file);
          //     }
          //     this.loading = false
          // } else {
          //     abp.message.success("操作成功");
          //     this.success(true);
          //     this.loading = false
          // }
        }
      });
    },

    getAllProject () {
      let url = this.$apiUrl + "/api/services/app/PersonnelTask/GetProjectList";
      this.$api
        .request({
          url: url,
          method: "GET",
          headers: { "Content-Type": "application/json-patch+json" },
        })
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.ProjectData.splice(0, this.ProjectData.length);
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              this.ProjectData.push({ id: item.id, name: item.projectName });
            }
          }
        })
        .catch((e) => {
          abp.message.warn(this.l("DefaultErrorMessage"));
        });
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    ProjectChange (value, e) {
      this.getAllTemplate(value);
    },
    downLoad () {
      //   window.location.href = '/门店导入模板.xls'
      fileHelper.downloadTemplateFile("批量创建派发人员任务导入模板.xls");
    },
    handleChange (info) {
      this.fileList = info.fileList;
      if (info.file.status === "done") {
        const res = info.file.response;
        if (res.code === 200) {
        } else {
          this.fileList.pop();
          this.$message.error(`文件上传失败`);
        }
      } else if (info.file.status === "error") {
        this.$message.error(`文件上传失败`);
      }
    },

    //限制文件上传大小
    beforeUpload (file) {
      //   console.log(file);
      // 设置为false 手动上传
      this.importFile = file;
      return false;
    },
    getAllTemplate (projectId, callback) {
      let url =
        this.$apiUrl + "/api/services/app/Task/GetProjectTaskTemplateList";
      this.$api
        .request({
          url: url,
          method: "GET",
          params: { projectId: projectId },
          headers: { "Content-Type": "application/json-patch+json" },
        })
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.TemplateData.splice(0, this.TemplateData.length);
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              this.TemplateData.push({
                id: item.id,
                name: item.taskTemplateName,
              });
            }
          }
          if (callback && typeof callback == "function") {
            callback();
          }
        })
        .catch((e) => {
          abp.message.warn(this.l("DefaultErrorMessage"));
        });
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}

.ant-cascader-picker {
  width: 85% !important;
}

.ant-btn-primary {
  margin-left: 10px;
}

.formScroll {
  max-height: 600px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.dianmi-select {
  width: 40%;
  margin-left: 5px;
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>
