<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title">
                <span>任务详情</span>
            </div>
        </div>
        <div>
            <a-tabs default-active-key="1" @change="callback">
                <a-tab-pane key="1" tab="任务详情" force-render>
                    <task-detail-pannel :TaskId="TaskId"></task-detail-pannel>
                </a-tab-pane>
                <a-tab-pane key="2" tab="任务人员" >
                    <task-personnel-pannel :taskId="TaskId"></task-personnel-pannel>
                </a-tab-pane>
                <a-tab-pane key="3" tab="考勤打卡">
                    <task-duty-pannel :taskId="TaskId"></task-duty-pannel>
                </a-tab-pane>
                <a-tab-pane key="4" tab="执行记录">
                    <task-report-pannel :taskId="TaskId"></task-report-pannel>
                </a-tab-pane>
            </a-tabs>
        </div>
    </a-spin>
</template>
<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import TaskDetailPannel from "../task-detail-pannel/task-detail-pannel";
    import TaskPersonnelPannel from "../task-personnel-pannel/task-personnel-pannel";
    import TaskDutyPannel from "../task-duty-pannel/task-duty-pannel";
    import TaskReportPannel from "../task-report-pannel/task-report-pannel"
    import { ModalHelper } from "@/shared/helpers";
    export default {
        name: "task-details",
        mixins: [ModalComponentBase],
        components: {
            TaskDetailPannel,
            TaskPersonnelPannel,
            TaskDutyPannel,
            TaskReportPannel
        },
        data() {
            return {
                spinning: false,
                TaskId: ''
            };
        },
        created() {
            this.fullData();
        },
        mounted() {
            this.TaskId = this.id;
        },
        methods: {
            callback(key) {
                console.log(key);
            },
        },
    };
</script>