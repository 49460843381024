<template>
  <a-spin :spinning="spinning">
      
      <!-- <react-table
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :columns="columns"
        :current-page="pageNumber"
        :scroll="{x: 900}"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
        ref="rt"
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="false"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="false"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a slot="totalCount" slot-scope="text">{{ text }}</a>
        <a-row :gutter="8" align="middle" type="flex">
          <a-col class="gutter-row" :span="4">
            <a-select
              style="width: 100%"
              placeholder="请选择审核状态"
              v-model="SearchDto.status"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in statusList"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <a-range-picker
              :placeholder="['开始日期', '结束日期']"
              @change="onPickerChange"
            />
          </a-col>
          <a-col class="gutter-row" :span="4">
            <a-input v-model="SearchDto.Name" :placeholder="l('姓名')" />
          </a-col>
          <a-col class="gutter-row" :span="4">
            <a-button type="primary" @click="search">
              {{ l("Search") }}
            </a-button>
            <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
          </a-col>
          <a-col class="gutter-row" :span="6" align="right">
            <a-button @click="BatchApply">
              <a-icon type="audit" />
              <span>{{ l("批量审核") }}</span>
            </a-button>

            <a-dropdown v-if="isGranted('Pages.LGModule.Task.ImportWithdrawExecuteRecord')">
              <a-button type="danger" @click="importExcel">导入执行记录 <a-icon type="down" /></a-button>
              <a-menu slot="overlay" @click="importExcelFile">
                <a-menu-item>
                  <a href="javascript:;">导入执行记录文件</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>

            <!-- <a-button type="primary" @click="importExcel">
              <a-icon type="file-excel" />
              <span>{{ l("导入执行记录") }}</span>
            </a-button>
            <a-button type="primary" @click="importExcelFile">
              <a-icon type="file-excel" />
              <span>{{ l("导入执行记录文件") }}</span>
            </a-button> -->
          </a-col>
        </a-row>
      </ele-table>
    <a-modal title="Title" :visible="showBatchApply" @cancel="hideBatchApply">
      <a-row :gutter="24">
        <a-col span="24">
          <label>不通过原因：</label>
        </a-col>
        <a-col span="24">
          <a-textarea :row="6" v-model="rejectReason"></a-textarea>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="handleSubmit(0)">不通过</a-button>
        <a-button key="submit" type="primary" @click="handleSubmit(1)"
          >通过</a-button
        >
      </template>
    </a-modal>
    <!--导入执行记录-->
    <a-modal title="导入执行记录" :visible="showReportImport" @cancel="closeReportImportModal">
      <a-row :gutter="24">
        <a-col span="24">
          <a-upload-dragger
            v-if="this.uploadFileList.length == 0"
            name="file"
            :multiple="false"
            accept=".xlsx"
            @reject="fileError"
            @change="uploadFileChange"
            :showUploadList="false"
            :before-upload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">
              点击此处或者将文件拖拽至此处开始上传
            </p>
            <p class="ant-upload-hint">
              只支持xlsx文件，只能上传单个文件。
            </p>
          </a-upload-dragger>
          <a-tag closable color="#87d068" @close="fileDelete" v-if="this.uploadFileList.length > 0">{{uploadFileList.length > 0 ? uploadFileList[0].name : ''}}</a-tag>
        </a-col>
        <a-col span="24" style="margin-top:10px;">
          <a-button type="default" @click="downloadTemplate">下载模板</a-button>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button type="primary" @click="handleFileUpload()">导入</a-button>
        <a-button  @click="closeReportImportModal">关闭</a-button>
      </template>
    </a-modal>

    <!--导入执行记录采集表单文件-->
    <a-modal title="导入执行记录采集表单文件" :visible="showReportImportFile" @cancel="closeReportImportFileModal">
      <a-row :gutter="24">
        <a-col span="24">
          <a-upload-dragger
            v-if="this.uploadFileList.length == 0"
            name="file"
            :multiple="false"
            accept=".zip"
            @reject="fileError1"
            @change="uploadFileChange"
            :showUploadList="false"
            :before-upload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">
              点击此处或者将文件拖拽至此处开始上传
            </p>
            <p class="ant-upload-hint">
              只支持zip压缩文件，只能上传单个文件。
            </p>
          </a-upload-dragger>
          <a-tag closable color="#87d068" @close="fileDelete" v-if="this.uploadFileList.length > 0">{{uploadFileList.length > 0 ? uploadFileList[0].name : ''}}</a-tag>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button type="primary" @click="handleZipFileUpload()">导入</a-button>
        <a-button  @click="closeReportImportFileModal">关闭</a-button>
      </template>
    </a-modal>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import CollectionFormResult from "./collectionformresult.vue";
import { fileDownloadService } from "@/shared/utils";
import axios from 'axios';

let vm = this;

export default {
  mixins: [AppComponentBase],
  name: "task-report-pannel",
  props: ["taskId"],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      showBatchApply: false,
      showReportImport: false,
      showReportImportFile: false,
      uploadFileList: [],
      SearchDto: {
        SkipCount: 0,
        MaxResultCount: 50,
        Sorting: "",
        filterText: "",
        Name: "",
        startDate: "",
        endDate: "",
        status: undefined,
      },
      name: "",
      projectData: [],
      selectedRowKeys: [],
      rejectReason: "",
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      child: "",
      commonService: null,
      selectProjectList: "",
      statusList: [
        { label: "未审核", value: 0 },
        { label: "审核通过", value: 1 },
        { label: "已结算", value: 2 },
        { label: "审核驳回", value: -1 },
      ],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    this.getData();
    this.initActionsType();
  },
  methods: {
    initColumns() {
      var vm = this;
      this.columns = [
        {
          title: this.l("姓名"),
          dataIndex: "realName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: this.l("执行名称"),
          dataIndex: "taskTitle",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "taskTitle" },
        },
        {
          title: this.l("执行次数"),
          dataIndex: "executionNum",
          sorter: false,
          align: "center",
          width: 120,
          scopedSlots: { customRender: "executionNum" },
        },
        {
          title: this.l("执行时间"),
          dataIndex: "executeTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "executeTime" },
        },
        {
          title: this.l("状态"),
          dataIndex: "isAuditedStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "isAudited" },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          fixed: "right",
          width: 140,
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    initChild(text) {
      var child = this.$createElement("a", {
        domProps: {
          innerHTML: "点击查看",
        },
        on: {
          click: function () {
            this.signClick(text);
          },
        },
      });
      this.child = child;
      console.log(child);
    },
    search() {
      this.pageNumber = 1
      this.SearchDto.SkipCount = 0
      this.getData()
    },
    getData() {
      this.getPaged();
    },
    async getPaged() {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      })
      try {
        let res = await this.commonService.post({
          url:
            "/api/services/app/PersonnelTask/GetTaskPersonnelExecuteRecordsPagedByTaskId",
          params: {
            taskId: this.taskId,
            status: this.SearchDto.status,
            name: this.SearchDto.Name,
            startDate: this.SearchDto.startDate,
            endDate: this.SearchDto.endDate,
            filterText: this.SearchDto.filterText,
            sorting: this.SearchDto.Sorting,
            maxResultCount: this.SearchDto.MaxResultCount,
            skipCount: this.SearchDto.SkipCount,
          },
        });
        console.log(res);
        this.tableData = res.items.map((item) => {
          let auditStatus = "待审核";
          if (item.status == 1) {
            auditStatus = "审核通过";
          } else if (item.status == 2) {
            auditStatus = "已结算";
          } else if (item.status == -1) {
            auditStatus = "驳回";
          }
          return {
            ...item,
            executeTimeStr: item.executeTime
              ? moment(item.executeTime).format("YYYY-MM-DD")
              : "-",
            isAuditedStr: auditStatus,
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "sign-manage",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("查看详情"),
              icon: "eye",
              fn: (data) => {
                // _this._getCollectionFormResult(data);
                       if(data.isHaveCollectionFormResult)
                {
                _this._getCollectionFormResult(data);
                }else {
                  _this.$message.info("该执行详情不存在");
                }
              },
            },
            // {
            //   granted: true,
            //   name: this.l("查看已签署人员"),
            //   icon: "ant-design",
            //   fn: (data) => {
            //     _this.lookSignPerson(data);
            //   },
            // },
            // {
            //   granted: true,
            //   name: this.l("查看未签署人员"),
            //   icon: "ant-design",
            //   fn: (data) => {
            //     _this.lookSignPerson(data);
            //   },
            // },
          ],
        },
      };
      this.actionsType = obj;
    },
    // 排序更新data
    updateSortData(newV) {
      if (newV.columnKey == "isAuditedStr") {
        newV.columnKey = "auditStatus";
      }
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * this.SearchDto.MaxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    lookSign(item) {
      const { pdfTemplateUrl } = item;
      if (pdfTemplateUrl) {
        window.open(`${AppConsts.uploadBaseUrl}${pdfTemplateUrl}`);
      } else {
        this.$message.info("暂无协议");
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    refreshGoFirstPage() {
      (this.SearchDto.Name = ""), (this.SearchDto.filterText = "");
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.status = null;
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    signClick(item) {
      //   this.message.success(text);
      this.lookSignPerson(item, 1);
    },
    noSignClick(item) {
      //   this.message.success("测试");
      this.lookSignPerson(item, 0);
    },
    lookSignPerson(item, signstatus) {
      ModalHelper.create(
        signperonlist,
        {
          signPerson: item,
          signstatus: signstatus,
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    onPickerChange(date, dateString) {
      console.log(date, dateString);
      this.SearchDto.startDate = dateString[0];
      this.SearchDto.endDate = dateString[1];
    },
    async _getCollectionFormResult(data) {
      this.spinning = true
      try {
        this.spinning = false
        ModalHelper.create(
          CollectionFormResult,
          { recordId: data.id, collectionFormId: data.collectionId },
          {
            isChange: true,
            width: "600px",
          }
        ).subscribe((res) => {
          if (res) {
            this.refreshGoFirstPage();
          }
        });
      } catch (error) {
        this.spinning = false
      }
      
    },
    BatchApply() {
      if (this.selectedRowKeys.length <= 0) {
        abp.message.warn("至少选择一行");
        return false;
      }
      
      for (let i = 0; i < this.selectedRowKeys.length; i++) {
        let index = this.selectedRowKeys[i];
        let rows = this.tableData.filter(item => item.id == index);
        let row = rows ? rows[0] : null;
        if (row && row.isAuditedStr != "待审核") {
          abp.message.warn("只能对待审核状态的数据进行审核");
          return false;
        }
      }
      this.showBatchApply = true;
    },
    hideBatchApply() {
      this.showBatchApply = false;
    },
    handleSubmit(pass) {
      let ids = [];
      for (var i = 0; i < this.selectedRowKeys.length; i++) {
        let index = this.selectedRowKeys[i];
        let rows = this.tableData.filter(item => item.id == index);
        let row = rows ? rows[0] : null;
        if(row && row.id){
          console.info('push')
          ids.push(row.id);
        }
      }
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/PersonnelTask/AuditedTaskRecord",
        method: "POST",
        data: {
          recordIds: ids,
          rejectReason: pass == 0 ? this.rejectReason : "",
          isPass: pass == 0 ? false : true,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            abp.message.success("审核成功");
            this.hideBatchApply();
            this.getPaged();
          } else {
            abp.message.error(res.statusText);
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    importExcel() {
      this.showReportImport = true;
      this.uploadFileList.splice(0, this.uploadFileList.length);
    },
    importExcelFile () {
      this.showReportImportFile = true;
      this.uploadFileList.splice(0, this.uploadFileList.length);
    },
    fileDelete(){
      this.uploadFileList.splice(0, this.uploadFileList.length);
    },
    uploadFileChange(info){
      console.info(info);
    },
    closeReportImportModal(){
      this.showReportImport = false;
    },
    handleFileUpload(){
      if (this.uploadFileList.length <= 0) {
        this.$message.error("您尚未选择文件");
        return false;
      }
      var curFile = this.uploadFileList[0];
      const formData = new FormData();
      formData.append("file", curFile);
      const headers = {
        "Authorization": "Bearer " + abp.auth.getToken(),
        "Content-Type": "multipart/form-data"
      };
      var httpReq = axios.create({
        baseURL: this.$apiUrl,
        headers: headers
      });
      const url = "/api/services/app/PersonnelTask/UploadTasExecute?taskId=" + this.taskId;
      httpReq.post(url, formData).then(res => {
        if(res.status == 200) {
          let data = res.data;
          if(data.success) {
            if(data.result != null) {
              fileDownloadService.downloadTempFile(data.result);
              if(data.result.fileName.indexOf(".zip") > 0)
              {//需要上传文件
                  this.$message.success("导入成功，需要导入采集表单的文件，请解压下载的压缩包，按要求操作");
                  this.getData();
                  this.showReportImportFile = true;
                  this.closeReportImportModal();
                  this.uploadFileList.splice(0, this.uploadFileList.length);
                  return;
              } else{
                this.$message.error("上传成功，但是导入的文件数据有问题，请在下载的excel文件里面确认并修改后再上传");
              }
              return;
            } 
            this.$message.success("导入成功");
            this.getData();
            this.closeReportImportModal();
            this.getPaged();
            return;
          }
          this.$message.error(data.error.message);
          return;
        }
        this.$message.error(res.statusText);
      });
    },
    fileError(fileList) {
      fileList.pop();
      this.$message.error("不支持的文件类型，请选择xlsx类型文件");
    },
    fileError1(fileList) {
      fileList.pop();
      this.$message.error("不支持的文件类型，请选择zip类型文件");
    },
    beforeUpload(file) {
      this.uploadFileList.splice(0, this.uploadFileList.length);
      this.uploadFileList.push(file);
      return false;
    },
    downloadTemplate(){
      this.commonService.get({
        url: "/api/services/app/PersonnelTask/TaskExecuteTemplate",
        params: {
          taskId: this.taskId
        }
      }).then(res => {
        fileDownloadService.downloadTempFile(res);
      });
    },
    closeReportImportFileModal(){
      this.showReportImportFile = false;
    },
    handleZipFileUpload(){
      if (this.uploadFileList.length <= 0) {
        this.$message.error("您尚未选择文件");
        return false;
      }
      var curFile = this.uploadFileList[0];
      const formData = new FormData();
      formData.append("file", curFile);
      const headers = {
        "Authorization": "Bearer " + abp.auth.getToken(),
        "Content-Type": "multipart/form-data"
      };
      var httpReq = axios.create({
        baseURL: this.$apiUrl,
        headers: headers
      });
      const url = "/api/services/app/PersonnelTask/UploadTasExecuteZip?taskId=" + this.taskId;
      httpReq.post(url, formData).then(res => {
        if(res.status == 200) {
          let data = res.data;
          if(data.success) {
            if(data.result == "success"){
              this.$message.success("导入文件成功");
            } else {
              this.$message.success(data.result);
            }
            this.closeReportImportFileModal();
            this.getPaged();
            this.uploadFileList.splice(0, this.uploadFileList.length);
            return;
          }
          this.$message.error(data.error);
          return;
        }
        this.$message.error(res.statusText);
      }).catch(e => {
        this.$message.error(e.response.data.error.message);
      })
    }
  },
};
</script>

<style scoped lang="less">
</style>
