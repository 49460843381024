<template>
  <a-spin :spinning="spinning">
      
      <!-- 第二期再做 -->
      <!-- <a-row :gutter="8" :style="{ textAlign: 'right' }">
        <a-col class="gutter-row" :span="24">
          <a-button @click="exportToExcel()">
            <a-icon type="file-excel" />
            <span>{{ l("导入") }}</span>
          </a-button>
        </a-col>
      </a-row> -->
      <!-- <react-table
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :columns="columns"
        :current-page="pageNumber"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
        ref="rt"
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="false"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="false"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a slot="totalCount" slot-scope="text">{{ text }}</a>
        <a-row :gutter="8">
          <a-col class="gutter-row" :span="5">
            <a-input v-model="SearchDto.name" :placeholder="l('姓名')" />
          </a-col>
          <a-col class="gutter-row" :span="5">
            <a-input v-model="SearchDto.phoneNum" :placeholder="l('手机号码')" />
          </a-col>
          <a-col class="gutter-row" :span="5">
            <a-input v-model="SearchDto.idCard" :placeholder="l('身份证号')" />
          </a-col>
          <a-col class="gutter-row" :span="4">
            <a-button type="primary" @click="search">
              {{ l("Search") }}
            </a-button>
            <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
          </a-col>
        </a-row>
      </ele-table>

      <a-modal
        centered
        :title="inputModalParams.title"
        :visible="inputModalParams.visible"
        :confirm-loading="inputModalParams.confirmLoading"
        :cancelText="inputModalParams.cancelText"
        :okText="inputModalParams.okText"
        :maskClosable="inputModalParams.maskClosable"
        :destroyOnClose="inputModalParams.destroyOnClose"
        :width="inputModalParams.width"
        @ok="inputModalParams.confirm"
        @cancel="inputModalParams.cancel"
      >
        <div>
          <a-form :form="form" v-bind="formItemLayout">
            <a-form-item label="终止原因">
              <a-input v-decorator="['reason']" type="textarea" />
            </a-form-item>
          </a-form>
        </div>
      </a-modal>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import moment from "moment";
import { CommonServiceProxy, initTaskStatus } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";

let vm = this;

export default {
  mixins: [AppComponentBase],
  name: "task-personnel-pannel",
  props: ["taskId"],
  components: { EleTable },

  data() {
    return {
      spinning: false,
      SearchDto: {
        SkipCount: 0,
        MaxResultCount: 50,
        Sorting: "",
        filterText: "",
        name: "",
        phoneNum: "",
        idCard: "",
      },
      name: "",
      projectData: [
        // {
        //   value: "1",
        //   text: "测试项目1",
        // },
        // {
        //   value: "2",
        //   text: "测试项目2",
        // },
      ],
      selectedRowKeys: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      child: "",
      commonService: null,
      selectProjectList: "",
      actionsType: null,
      form: this.$form.createForm(this, { name: "coordinated" }),
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "审核",
        cancelText: "取消",
        okText: "确认",
        form: {},
        id: null,
        disabled: false,
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
    };
  },
  created() {
    console.log(this);
    // this.initChild();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    // this.getProject();
    if (this.taskId) {
      this.getData();
    }
    this.initActionsType();
  },
  methods: {
    initColumns() {
      var vm = this;
      //   var child = vm.$createElement("a", {
      //     domProps: {
      //       innerHTML: 66,
      //     },
      //     on: {
      //       click: function () {
      //         vm.signClick();
      //       },
      //     },
      //   });
      this.columns = [
        {
          title: this.l("姓名"),
          dataIndex: "name",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("性别"),
          dataIndex: "sex",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "sex" },
        },
        {
          title: this.l("年龄"),
          dataIndex: "age",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "age" },
        },
        {
          title: this.l("手机号码"),
          dataIndex: "phone",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "phone" },
        },
        {
          title: this.l("身份证号码"),
          dataIndex: "idCardNum",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "idCardNum" },
        },
        {
          title: this.l("开始日期"),
          dataIndex: "startTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "startTime" },
        },
        {
          title: this.l("结束日期"),
          dataIndex: "endTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "endTime" },
        },
        {
          title: this.l("状态"),
          dataIndex: "taskStatus",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "TaskStatus" },
          customRender: function (text, record, index) {
            if (text == "0") {
              return "审核中";
            }
            if (text == "1") {
              return "待签约";
            }
            if (text == "2") {
              return "任务进行中";
            }
            if (text == "3") {
              return "任务已经完结";
            }
            if (text == "4") {
              return "拒绝";
            }
            if (text == "5") {
              return "任务终止";
            }
            return "";
          },
        },
        // {
        //   title: this.l("来源"),
        //   dataIndex: "source",
        //   sorter: true,
        //   align: "center",
        //   customRender: function (text, record, index) {
        //     console.log(record);
        //     var child = vm.$createElement("a", {
        //       domProps: {
        //         innerHTML: text,
        //       },
        //       on: {
        //         click: function () {},
        //       },
        //     });
        //     var obj = {
        //       children: child,
        //       attrs: {},
        //     };
        //     return obj;
        //   },
        //   scopedSlots: { customRender: "source" },
        // },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          sorter: false,
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    initChild(text) {
      var child = this.$createElement("a", {
        domProps: {
          innerHTML: text,
        },
        on: {
          click: function () {
            this.signClick(text);
          },
        },
      });
      this.child = child;
      console.log(child);
    },
    search() {
      this.pageNumber = 1
      this.SearchDto.SkipCount = 0
      this.getData()
    },
    getData() {
      this.getPaged();
      // this.tableData = [
      //   {
      //     name: "王一博",
      //     sex: "男",
      //     age: "20岁",
      //     phone: "13813813801",
      //     idCard: "1111111111",
      //     startDate: "2020.02.23 12:00",
      //     endDate: "2020.02.23 12:00",
      //     source: "小程序",
      //   },
      //   {
      //     name: "王一博",
      //     sex: "男",
      //     age: "20岁",
      //     phone: "13813813801",
      //     idCard: "1111111111",
      //     startDate: "2020.02.23 12:00",
      //     endDate: "2020.02.23 12:00",
      //     source: "导入",
      //   },
      // ];
    },
    async getPaged() {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      })
      try {
        let res = await this.commonService.post({
          url:
            "/api/services/app/PersonnelTask/GetTaskPersonnelForAuditedPagedByTaskId",
          params: {
            taskId: this.taskId,
            filterText: this.SearchDto.filterText,
            sorting: this.SearchDto.Sorting,
            maxResultCount: this.SearchDto.MaxResultCount,
            skipCount: this.SearchDto.SkipCount,
            name: this.SearchDto.name,
            phoneNum: this.SearchDto.phoneNum,
            idCard: this.SearchDto.idCard,
          },
        });
        // console.log(res);
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            startTimeStr: item.startTime
              ? moment(item.startTime).format("YYYY-MM-DD")
              : "-",
            endTimeStr: item.endTime
              ? moment(item.endTime).format("YYYY-MM-DD")
              : "-",
            taskStatus: initTaskStatus(item.taskStatus),
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "sign-manage",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              sort: false,
              name: this.l("终止任务人员"),
              icon: "profile",
              fn: (data) => {
                // _this.lookSign(data);
                if (
                  data.personnelTaskStatusName == "任务进行中" ||
                  data.personnelTaskStatusName == "待签约"
                ) {
                  _this._audit(data.id);
                } else {
                  _this.message.warn("无法终止！");
                }
              },
            },
            // {
            //   granted: true,
            //   name: this.l("查看已签署人员"),
            //   icon: "ant-design",
            //   fn: (data) => {
            //     _this.lookSignPerson(data);
            //   },
            // },
            // {
            //   granted: true,
            //   name: this.l("查看未签署人员"),
            //   icon: "ant-design",
            //   fn: (data) => {
            //     _this.lookSignPerson(data);
            //   },
            // },
          ],
        },
      };
      this.actionsType = obj;
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * this.SearchDto.MaxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    lookSign(item) {
      const { pdfTemplateUrl } = item;
      if (pdfTemplateUrl) {
        window.open(`${AppConsts.uploadBaseUrl}${pdfTemplateUrl}`);
      } else {
        this.$message.info("暂无协议");
      }
    },
    _audit(presonneltaskId) {
      let _this = this;
      this.inputModalParams.title = "是否终止人员任务";
      this.inputModalParams.confirm = function () {
        _this.form.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.inputModalParams.confirmLoading = true;
            try {
              _this.end(presonneltaskId, values.reason);
              _this.inputModalParams.confirmLoading = false;
              _this.inputModalParams.visible = false;
            } catch (error) {
              _this.inputModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.inputModalParams.visible = true;
    },
    async end(personneltaskId, reason) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/PersonnelTask/EndPersonnelTask",
          params: {
            personneltaskId: personneltaskId,
            reason: reason,
          },
        });
        this.$notification["success"]({
          message: res,
        });
        this.getData();
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    refreshGoFirstPage() {
      this.SearchDto.filterText = "";
      this.SearchDto.name = "";
      this.SearchDto.phoneNum = "";
      this.SearchDto.idCard = "";
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    signClick(item) {
      //   this.message.success(text);
      this.lookSignPerson(item, 1);
    },
    noSignClick(item) {
      //   this.message.success("测试");
      this.lookSignPerson(item, 0);
    },
    lookSignPerson(item, signstatus) {
      ModalHelper.create(
        signperonlist,
        {
          signPerson: item,
          signstatus: signstatus,
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    // onChange(date, dateString) {
    //   console.log(date, dateString);
    // },
  },
};
</script>

<style scoped lang="less">
</style>
