import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {FileDto, SwaggerException} from "@/shared/service-proxies/model";
import apiHttpClient from '@/shared/utils/api-http-client';
import {AppConsts} from '@/abpPro/AppConsts';
import {throwError} from "rxjs";
import {UrlHelper} from "../../../helpers";
import {message} from "ant-design-vue";
import {formatInternalValue} from "ant-design-vue/lib/vc-tree-select/src/util";
import objectHelper from "../../../helpers/objectHelper";

let workOrderApi = {
    namespaces: 'workOrder',
    instance: apiHttpClient,
    locked: false,
    localStore: {},
    getInstance: function () {
        return this.instance = this.instance ? this.instance : axios.create()
    },
    getTakenList:async function(params){
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/getTakenWorkOrders";
        let options_ = {
            method: "GET",
            url:  UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
    getPagedList: async function (params) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/GetPaged";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },

    async close(id) {
        console.log('???????')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/close";
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: {id: id}
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
    async take(id) {
        console.log('???????')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/Take";
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: {id: id}
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },

    async save(item) {
        console.log('???????')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/SaveWorkOrder";
        var content = objectHelper.deepClone(item)
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: content
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    }
}

export default workOrderApi