<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title"><a-icon type="plus" />添加任务人员</div>
    </div>
   
    <ele-table
      ref="rt"
      :table-data="tableData"
      :total-items="totalItems"
      :actions-type="actionsType"
      :columns="columns"
      :hide-row-selection="false"
      :hide-pagination="false"
      :current-page="pageNumber"
      :is-full="false"
      @emitRefreshData="getData"
      @emitSelectChange="selectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
       <!-- 按钮 -->
      <a-row :gutter="8" class="">
        <a-col class="gutter-row" :span="6">
          <a-input v-model="searchDto.realName" :placeholder="l('请输入姓名')" />
        </a-col>
        <a-col class="gutter-row" :span="6">
          <a-input
            v-model="searchDto.phoneNum"
            :placeholder="l('请输入手机号码')"
          />
        </a-col>
        <a-col class="gutter-row" :span="8">
          <a-button type="primary" @click="search">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" :span="4" align="right">
          <a-button @click="batchAddTaskPersonnel()" type="primary">
            <a-icon type="plus" />
            {{ l("批量添加") }}
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import EleTable from "@/components/ele-table";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";

export default {
  name: "addTaskPersonnel",
  mixins: [AppComponentBase, ModalComponentBase],
  components: { EleTable },
  data() {
    return {
      filterText: "",
      spinning: false,
      // 表单
      formLayout: "horizontal",
      isDisabled: false,

      tableKey: "",
      commonService: null,
      personnelService: null,

      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      signStatuList: ["未签署", "单方签署", "已经签署", "归档", "已通知"],
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      // 用户表格
      columns: [
        {
          title: this.l("姓名"),
          dataIndex: "realName",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: this.l("身份证号"),
          dataIndex: "icCard",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "icCard" },
        },
        {
          title: this.l("手机号"),
          dataIndex: "phone",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "phone" },
        },
        {
          title: this.l("审核状态"),
          dataIndex: "auditStatus",
          sorter: true,
          align: "center",
          customRender: function (text, record, index) {
            return text == 1 ? "已审核" : "未审核";
          },
          scopedSlots: { customRender: "auditStatus" },
        },
        {
          title: this.l("所属项目"),
          dataIndex: "projectName",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "projectName" },
        },
        // {
        //   title: this.l("Actions"),
        //   dataIndex: "actions",
        //   fixed: "right",
        //   sorter: true,
        //   align: "center",
        //   scopedSlots: { customRender: "actions" },
        // },
      ],
      selectedRowKeys: [],
      selectedRows: [],
      searchDto: {
        realName: "",
        phoneNum: "",
        filterText: "",
        sorting: "",
        MaxResultCount: 50,
        SkipCount: 0,
      },
      projectData: [],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段

    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted() {
    console.log(this);
    if (this.params) {
      this.$nextTick(() => {});
    }
    if (this.taskId) {
      this.getData();
    }
    // this.getProject();
  },
  methods: {
    search() {
      this.pageNumber = 1
      this.searchDto.SkipCount = 0
      this.getData()
    },
    getData() {
      //   this.initActionsType();
      this._getPersonPagedByAgreementTemplateId();
    },
    async getProject() {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProject/GetProjectList",
          params: {},
        });
        this.projectData = res.map((item) => {
          return {
            value: item.id,
            text: item.projectName,
          };
        });
        // this.selectProjectList = res.result;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _getPersonPagedByAgreementTemplateId() {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      })
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/PersonnelTask/GetProjectPersonnelByTaskId",
          params: {
            taskId: this.taskId,
            realName: this.searchDto.realName,
            phoneNum: this.searchDto.phoneNum,
            filterText: this.searchDto.filterText,
            sorting: this.searchDto.sorting,
            maxResultCount: this.searchDto.MaxResultCount,
            skipCount: this.searchDto.SkipCount,
          },
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            auditStatus: item.auditStatus == 1 ? '已审核' : '未审核',
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "templete-list",
        isShow: true,
        fns: {
          dropList: [],
        },
      };
      if (this.signstatus == 1) {
        obj.fns.dropList = [
          {
            granted: true,
            name: this.l("预览协议"),
            icon: "eye",
            fn: (data) => {
              console.log(data);
              if (data.viewUrl) {
                window.open(data.viewUrl);
              } else {
                _this.$message.info("暂无协议");
              }
            },
          },
          {
            granted: true,
            name: this.l("下载协议"),
            icon: "download",
            fn: (data) => {
              if (data.downloadUrl) {
                window.location.href = data.downloadUrl;
              } else {
                _this.$message.info("暂无协议");
              }
            },
          },
        ];
      } else {
        obj.fns.dropList = [
          {
            granted: true,
            name: this.l("通知签署"),
            icon: "notification",
            fn: (data) => {
              console.log(data);
              if (data.isNoty) {
                _this.$message.info("已通知过!");
                return;
              }
              let personnelIds = [];
              personnelIds.push(data.personId);
              _this.notificationPersonnelAgreement(data.id, personnelIds);
            },
          },
          {
            granted: true,
            name: this.l("撤回"),
            icon: "delete",
            fn: (data) => {
              console.log(data);
              let personnelIds = [];
              personnelIds.push(data.personId);
              _this.delPersonnelAgreement(data.id, personnelIds);
            },
          },
        ];
      }
      this.actionsType = obj;
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.searchDto.realName = "";
      this.searchDto.phoneNum = "";
      this.searchDto.filterText = "";
      this.getData();
    },
    batchAddTaskPersonnel() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "添加"));
        return;
      }
      console.log(this.selectedRowKeys);
      console.log(this.selectedRows);
      let personnelIds = this.selectedRows.map((item) => {
        return item.userId;
      });
      let _that = this;
      _that.message.confirm(
        `是否对${personnelIds.length}个人进行添加`,
        "添加",
        (res) => {
          if (res) {
            _that.addData(personnelIds);
          }
        }
      );
    },
    async addData(personnelIds) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/PersonnelTask/BatchJoinTask",
          params: {
            taskId: this.taskId,
            personnelIds: personnelIds,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: res,
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    selectChange(data) {
      let { selectedRowKeys, selectedRows } = data;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    handleChange(value) {
      this.searchDto.projectId = value;
      console.log(value);
    },
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.searchDto.MaxResultCount = size;
      this.getData();
    },
  },
};
</script>

<style scoped lang="less">
// @import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
