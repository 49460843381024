<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title" style="width: 100%; text-align: left">
        <span v-if="id">{{ l("TaskEdit") }}</span>
        <span v-if="!id">{{ l("TaskAdd") }}</span>
      </div>
    </div>
    <a-form :form="form" @submit="handleSubmit" layout="horizontal">
      <div class="formScroll">
        <a-row :gutter="24">
          <a-col span="12">
            <!--所属项目-->
            <a-form-item :label="l('ProjectHeaderName')">
              <a-input :placeholder="l('ProjectHeaderName')" @click="selectProjectModal" v-decorator="[
                'Project',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                    { max: 30, message: l('validation.maxlength') },
                  ],
                },
              ]" />
              <a-input v-decorator="['ProjectId']" type="hidden" />
            </a-form-item>
          </a-col>

          <a-col span="12">
            <!--公司简称-->
            <a-form-item :label="l('CompanyShortName')">
              <a-input :placeholder="l('CompanyShortName')" v-decorator="[
                'DisplayCompanyName',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                    { max: 30, message: l('validation.maxlength') },
                  ],
                },
              ]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <a-form-item label="关联工单">
              <a-select v-model="workOrderId" defaultVlaue="" placeholder="请选择关联工单" :options="workOrderOptions"
                @change="workOrderChanged"></a-select>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item label="工单编号">
              <a-input v-model="workOrderNo" placeholder="工单编号" disabled></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!-- 任务名称 -->
            <a-form-item :label="l('TaskHeaderName')">
              <a-input :placeholder="l('TaskHeaderName')" v-decorator="[
                'TaskTitle',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" />
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!-- 任务编码 -->
            <a-form-item :label="l('TaskHeaderCode')">
              <a-input disabled v-decorator="[
                'TaskCode',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--任务模板-->
            <a-form-item :label="l('TaskHeadTemplate')">
              <a-select :placeholder="l('TaskHeadTemplate')" v-decorator="[
                'TemplateId',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" :disabled="id ? true : false">
                <a-select-option v-for="item in TemplateData" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!--计薪方式-->
            <a-form-item :label="l('结算方式')">
              <a-select :placeholder="l('结算方式')" v-decorator="[
                'SalaryType',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]">
                <a-select-option v-for="item in SalaryData" :key="item.key" :value="item.value">{{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--工价-->
            <a-form-item :label="l('TaskHeaderSalary')">
              <a-input-number placeholder="请输入工价" :step="0.01" :min="0.01" style="width: 100%" v-decorator="[
                'Salary',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" />
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!--学历要求-->
            <a-form-item :label="l('TaskHeadEdutation')">
              <a-select placeholder="请选择学历要求" v-decorator="['Education', { rules: [] }]" style="width: 100%">
                <a-select-option :value="item.value" v-for="item in StudyData" :key="item.key">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--性别要求-->
            <a-form-item :label="l('TaskHeadSexNeed')">
              <a-select placeholder="请选择性别要求" v-decorator="[
                'SexRequired',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" style="width: 100%">
                <a-select-option :value="item.value" v-for="item in SexRequiredData" :key="item.key">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!-- 岗位福利-->
            <a-form-item :label="l('TaskHeadWelfare')">
              <a-select placeholder="请选择岗位福利" mode="multiple" v-decorator="['Welfare', { rules: [] }]"
                style="width: 100%">
                <a-select-option v-for="item in WelfareData" :key="item.id" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--经验要求-->
            <a-form-item :label="l('TaskHeadYearNeed')">
              <a-select placeholder="请选择经验要求" v-decorator="[
                'Experience',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" style="width: 100%">
                <a-select-option :value="item.value" v-for="item in ExperienceData" :key="item.key">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!--所需人数-->
            <a-form-item :label="l('TaskHeaderNeeds')">
              <a-input-number placeholder="请输入所需人数" v-decorator="[
                'Needs',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]" :step="1" :min="1" style="width: 100%" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--结算限价-->
            <a-form-item :label="l('结算限价')">
              <span
                style="background-color: rgb(217,217,217);height: 32px;line-height: 32px;padding:0 7px;display: inline-block;margin-right: -2px;border: 1px solid rgb(217,217,217);">最高</span>
              <a-input-number placeholder="请输入最高结算价" v-decorator="[
                'MaxPrice',
                {
                  rules: [],
                },
              ]" :step="0.01" :min="0" style="width: 72%" :precision="2" />
              <span
                style="background-color: rgb(217,217,217);height: 32px;line-height: 32px;padding:0 7px;display: inline-block;margin-right: -2px;border: 1px solid rgb(217,217,217);">元</span>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!--任务是否单次执行-->
            <a-form-item :label="l('任务是否单次执行')">
              <a-switch v-decorator="['isSingleExecution', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="14">
            <a-form-item :label="l('TaskWorkCity')">
              <!--工作城市-->
              <div style="width: 100%">
                <a-select placeholder="省" v-model="CurrentProvince" label-in-value @change="ProvinceChange"
                  class="dianmi-select">
                  <a-select-option v-for="item in ProvinceData" :value="item.value" :key="item.value">{{ item.label }}
                  </a-select-option>
                </a-select>
                <a-select placeholder="市" v-model="CurrentCity" label-in-value class="dianmi-select" @change="CityChange">
                  <a-select-option v-for="item in CityData" :value="item.value" :key="item.value">{{ item.label }}
                  </a-select-option>
                </a-select>
                <a-button type="primary" shape="circle" icon="plus" @click="AddCascader" />
              </div>
            </a-form-item>
            <a-form-item v-for="item in DynamicCascader" :key="item.id">
              <div>
                <a-select placeholder="省" label-in-value @change="ChildProvinceChange" class="dianmi-select"
                  :defaultValue="item.province">
                  <a-select-option v-for="it in ProvinceData" :value="it.value + '-' + item.id" :key="it.value">
                    {{ it.label }}
                  </a-select-option>
                </a-select>
                <a-select placeholder="市" label-in-value class="dianmi-select" @change="ChildCityChange"
                  :defaultValue="item.city">
                  <a-select-option v-for="it in ChildrenCityData[item.id]" :key="it.value"
                    :value="it.value + '-' + item.id">{{ it.label }}
                  </a-select-option>
                </a-select>
                <a-button type="primary" shape="circle" icon="minus" @click="Remove(item.id)" />
              </div>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col span="12">
            <!--任务时间-->
            <a-form-item :label="l('TaskHeaderTime')">
              <a-range-picker :placeholder="['开始日期', '结束日期']" valueFormat="YYYY-MM-DD" v-decorator="[
                'TaskDate',
                {
                  rules: [
                    { required: true, message: l('validation.required') },
                  ],
                },
              ]">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date">{{ current.date() }}</div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!--是否在小程序任务大厅上显示-->
            <a-form-item :label="l('是否在小程序任务大厅上显示')">
              <a-switch v-decorator="['isDisplay', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="mode == 'create'">
          <a-col span="14">
            <a-form-item label="导入执行记录">
              <a-select v-model="importData" :options="options('Int.YN')"></a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="importData == 1">
          <a-col span="14">
            <a-form-item label="是否生成帐单">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>打开后会自动生成帐单</span>
                </template>
                <a-switch v-decorator="['isCreateBill']" />
              </a-tooltip>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="btn--container" v-if="importData == 1">
          <a-col class="gutter-row" :span="12" style="color: red; font-size: 20px">
            温馨提示：先下载模板
          </a-col>
          <a-col class="gutter-row" :span="12" style="text-align: right">
            <a-tooltip placement="top">
              <template slot="title">
                <span>下载模板填充后进行导入</span>
              </template>
              <a-button @click="downLoad" style="margin-right: 10px">
                <a-icon type="download" />
                <span>下载模板</span>
              </a-button>
            </a-tooltip>
          </a-col>
        </a-row>

        <a-row :gutter="24" style="margin-top: 10px" v-if="importData == 1">
          <a-col>
            <a-upload-dragger ref="uploadExcel" name="file" a-upload-dragger :multiple="false" :action="uploadUrl"
              accept=".xls,.xlsx" :before-upload="beforeUpload" :fileList="fileList" @change="handleChange">
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">将文件拖到此处或点击上传</p>
            </a-upload-dragger>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col span="24">
            <!-- 任务说明 -->
            <a-form-item :label="l('TaskHeadRemark')">
              <div id="TaskRemark"></div>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <a-row :gutter="24">
        <a-col span="24">
          <a-form-item class="btn--container" style="text-align: center">
            <a-button type="button" @click="close()">
              {{ l("Cancel") }}
            </a-button>
            <a-button :loading="loading" type="primary" html-type="submit">
              {{ l("Save") }}
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { ProjectServiceProxy, ProjectDto } from "@/shared/service-proxies";
import { setInterval, setTimeout } from "timers";
import { AppConsts } from "@/abpPro/AppConsts";
import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
import E from "editor4kg";
import { message } from "ant-design-vue";
import { fileDownloadService } from "@/shared/utils";
import fileHelper from "@/shared/helpers/fileHelper";
import taskApi from "@/shared/service-proxies/task/services/task-proxies";
import workOrderApi from "@/shared/service-proxies/workOrder/services/work-order-proxies";
import SelectPageList from "@/components/linggong/select-page-list";

export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-project",
  data () {
    return {
      workOrderOptions: [],
      // 获取到的数据
      mode: "create",
      entity: {},
      spinning: false,
      TimeInterval: null,
      Proxy: "",
      ClientData: [],
      CompanyData: [],
      ProjectData: [],
      InvoiceData: [],
      ProjectTypeData: [],
      SalaryData: [],
      StudyData: [],
      ExperienceData: [],
      WelfareData: [],
      ProvinceData: [],
      CityData: [],
      ChildrenCityData: [],
      SexRequiredData: [],
      TemplateData: [],
      DynamicCascader: [],
      WorkAddress: [],
      Province: "",
      City: "",
      ChildrenProvince: {},
      ChildrenCity: {},
      editor: null,
      CurrentProvince: null,
      CurrentCity: null,
      fileList: [],
      _fileDownloadService: "",
      uploadHeaders: {},
      importData: 0,
      uploadUrl: "",
      workOrderId: "",
      workOrderNo: "",
      workOrders: [],
      loading: false,
      importFile: undefined,
    };
  },
  components: {},
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created () {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.SalaryData = dataItemApi.getOptions("SalaryType");
    this.StudyData = dataItemApi.getOptions("Education");
    this.ExperienceData = dataItemApi.getOptions("Years");
    this.WelfareData = dataItemApi.getOptions("Welfare");
    this.SexRequiredData = dataItemApi.getOptions("SexRequired");
    this.getAllProject();
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
  },

  mounted () {
    this.getProvinceData("100000", null);
    const editor = new E("#TaskRemark");
    editor.config.menus = [
      "head",
      "bold",
      "fontSize",
      "fontName",
      "italic",
      "underline",
      "strikeThrough",
      "indent",
      "lineHeight",
      "foreColor",
      "backColor",
      "link",
      "list",
      "justify",
      "quote",
      "emoticon",
      "image",
      "table",
      "code",
      "splitLine",
      "undo",
      "redo",
      "selectValue",
    ];
    editor.create();
    this.editor = editor;
    this.getData();
  },
  methods: {
    selectProjectModal () {
      ModalHelper.create(
        SelectPageList,
        {
          //   selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
          console.log(data);
          this.form.setFieldsValue({
            Project: data.name,
            ProjectId: data.id,
            DisplayCompanyName: data.customerName,
          });

          this.getAllTemplate(data.id, () => { });
          workOrderApi.getTakenList({ projectId: data.id }).then((res) => {
            this.workOrders = res.items;
            this.workOrderOptions = res.items.map((item) => {
              return {
                label: item.woName,
                key: item.id,
                value: item.id,
                title: item.woName,
              };
            });
          });
        }
      });
    },
    /**
     * 获取数据
     */
    getData () {
      if (this.id && this.id != "") {
        this.mode = "edit";
        this.spinning = true;
        let options = {
          url: this.$apiUrl + "/api/services/app/Task/GetById",
          params: { id: this.id },
          method: "GET",
          headers: {
            "Content-Type": "application/json-patch+json",
          },
        };
        this.$api
          .request(options)
          .then((res) => {
            this.spinning = false;
            let data = res.data;
            if (data) {
              this.form.setFieldsValue({
                Project: data.projectName,
                DisplayCompanyName: data.displayCompanyName,
                Education: data.education,
                Experience: data.experienceRequest,
                Needs: data.numberOfNeed,
                ProjectId: data.projectId,
                Salary: data.price,
                SalaryType: data.denominated,
                SexRequired: data.sexRequest,
                TaskCode: data.taskCode,
                MaxPrice: data.maxPrice,
                TaskDate: [
                  data.startTime.substring(0, 10),
                  data.endTime.substring(0, 10),
                ],
                TaskTitle: data.taskTitle,
                Welfare:
                  data.postWelfare && data.postWelfare != ""
                    ? data.postWelfare.split(",")
                    : [],
                isDisplay: data.isDisplay ? true : false,
                isSingleExecution: data.isSingleExecution ? true : false,
              });
              this.getAllTemplate(data.projectId, () => {
                this.form.setFieldsValue({
                  TemplateId: data.taskTemplateId,
                });
              });
              if (data.content) {
                this.editor.txt.html(decodeURIComponent(data.content));
              }
              if (data.taskAreas && data.taskAreas.length > 0) {
                if (this.ProvinceData && this.ProvinceData.length <= 0) {
                  this.getProvinceData("100000", () => {
                    this.FillProvince(data.taskAreas);
                  });
                } else {
                  this.FillProvince(data.taskAreas);
                }
              }
            }
          })
          .catch((e) => {
            this.spinning = false;
            console.error(e);
          });
      } else {
        this.getTaskCode();
      }
    },
    FillProvince (taskAreas) {
      if (!taskAreas || taskAreas.length <= 0) {
        return false;
      }

      let first = taskAreas[0];
      first.province = first.province.replace(/\s/g, "");
      let items = this.ProvinceData.filter((e) => e.label == first.province);

      this.CurrentProvince = { key: items[0].value, label: items[0].label };
      this.Province = first.province;
      this.CurrentCity = { key: "", label: first.city };
      this.City = first.city;
      for (let i = 1; i < taskAreas.length; i++) {
        let it = taskAreas[i];
        let id = i.toString();
        this.DynamicCascader.push({
          id: i.toString(),
          province: { key: "", label: it.province },
          city: { key: "", label: it.city },
        });
        this.ChildrenProvince[id] = it.province;
        this.ChildrenCity[id] = it.city;
      }
    },
    /**
     * 提交表单
     */
    async handleSubmit (e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          let welfare = values.Welfare;
          let txt = "";
          if (welfare && welfare.length > 0) {
            for (let i = 0; i < welfare.length; i++) {
              txt += welfare[i] + ",";
            }
            txt = txt.substring(0, txt.length - 1);
          }
          var d = new FormData();
          d.append("projectId", values.ProjectId);
          d.append("taskCode", values.TaskCode);
          d.append("taskTitle", values.TaskTitle);
          d.append("positionType", "");
          d.append("taskTemplateId", values.TemplateId);
          d.append("denominated", values.SalaryType);
          d.append("price", values.Salary);
          d.append(
            "education",
            values.Education === undefined ? "" : values.Education
          );
          d.append("sexRequest", values.SexRequired);
          d.append("postWelfare", txt);
          d.append("experienceRequest", values.Experience);
          d.append("numberOfNeed", values.Needs);
          d.append("content", encodeURIComponent(this.editor.txt.html()));
          d.append("startTime", values.TaskDate[0]);
          d.append("endTime", values.TaskDate[1]);
          d.append("displayCompanyName", values.DisplayCompanyName);
          d.append(
            "id",
            this.id ? this.id : "00000000-0000-0000-0000-000000000000"
          );
          d.append("isNeedImport", values.importData);
          d.append(
            "isDisplay",
            values.isDisplay === undefined ? false : values.isDisplay
          );
          d.append(
            "isSingleExecution",
            values.isSingleExecution === undefined ? false : values.isSingleExecution
          );
          d.append("workOrderNo", this.workOrderNo);
          d.append("workOrderId", this.workOrderId);
          d.append(
            "isCreateBill",
            values.isCreateBill === undefined ? false : values.isCreateBill
          );
          if (values.MaxPrice && values.MaxPrice >= 0) {
            d.append('maxPrice', values.MaxPrice);
          }
          if (this.importData == 1) {
            if (this.importFile === undefined) {
              this.$message.warning("若要导入执行记录，请上传文件");
              return;
            }
            d.append("file", this.importFile);
            d.append("isNeedImportExecuteRecord", true);
          }
          let taskAreas = [];
          if (this.Province && this.Province != "") {
            //
            taskAreas.push({
              province: this.Province.replace(/\s/g, ""),
              city: this.City.replace(/\s/g, ""),
            });
          }
          if (this.ChildrenProvince) {
            for (let key in this.ChildrenProvince) {
              let province = this.ChildrenProvince[key];
              let city = "";
              if (this.ChildrenCity[key]) {
                city = this.ChildrenCity[key];
              }
              //
              taskAreas.push({
                province: province.replace(/\s/g, ""),
                city: city.replace(/\s/g, ""),
              });
            }
          }
          d.append(
            "taskAreas",
            taskAreas.length > 0 ? JSON.stringify(taskAreas) : ""
          );

          this.loading = true;
          this.spinning = true;
          let res = await taskApi.createTask(d);
          console.log(res);
          if (
            res.fileInfo &&
            res.fileInfo.fileName &&
            res.fileInfo.fileToken &&
            res.fileInfo.fileType
          ) {
            this.$message.warning(res.message);
            fileDownloadService.downloadTempFile(res.fileInfo);
          } else {
            if (res.success || res.fileInfo === null) {
              this.$message.success("创建成功");
              this.showUploadList = true;
              this.success(true);
            }
          }
          this.loading = false;
          this.spinning = false;

          // if (respData.code != "200") {
          //     this.spinning = false;
          //     if (respData.error.code == 505) {
          //         let file = JSON.parse(respData.error.details);
          //         // console.log(file);
          //         fileHelper.downloadTempFile(file);
          //     }
          //     this.loading = false
          // } else {
          //     abp.message.success("操作成功");
          //     this.success(true);
          //     this.loading = false
          // }
        }
      });
    },

    getProvinceData (code, callback) {
      let options = {
        url: this.$apiUrl + "/api/services/app/Area/GetSelectData",
        method: "GET",
        params: { code: code },
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res && res.data && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              let item = res.data[i];
              this.ProvinceData.push({
                value: item.value,
                label: item.text,
              });
            }
          }
          if (callback && typeof callback == "function") {
            callback(res);
            return;
          }
        })
        .catch((e) => {
          if (callback && typeof callback == "function") {
            callback(null);
          }
          console.error(e);
        });
    },
    ProvinceChange (item, e) {
      this.CityData.splice(0, this.CityData.length);
      this.City = "";
      this.Province = item.label;
      this.getProvinceData(item.key, (res) => {
        if (res && res.data) {
          let data = res.data;
          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            this.CityData.push({
              value: item.value,
              label: item.text,
            });
          }
        }
      });
    },
    CityChange (item, e) {
      this.City = item.label;
    },
    ChildProvinceChange (item, e) {
      let array = item.key.split("-");
      let value = array[0];
      let id = array[1];
      //   console.info(id);
      this.ChildrenProvince[id] = item.label;
      if (this.ChildrenCityData[id]) {
        this.ChildrenCityData[id].splice(0, this.ChildrenCityData[id].length);
      }
      this.getProvinceData(value, (res) => {
        if (res && res.data) {
          let data = res.data;
          this.ChildrenCityData[id] = [];
          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            this.ChildrenCityData[id].push({
              value: item.value,
              label: item.text,
            });
            this.ChildrenCityData = [...this.ChildrenCityData];
          }
        }
      });
    },
    ChildCityChange (item, e) {
      let array = item.key.split("-");
      let id = array[1];
      this.ChildrenCity[id] = item.label;
    },
    AddCascader () {
      let id = this.DynamicCascader.length + 1;
      this.DynamicCascader.push({ id: id.toString() });
    },
    Remove (id) {
      for (let i = 0; i < this.DynamicCascader.length; i++) {
        let item = this.DynamicCascader[i];
        if (item.id == id) {
          this.DynamicCascader.splice(i, 1);
          break;
        }
      }
      delete this.ChildrenProvince[id];
      delete this.ChildrenCity[id];
    },
    getTaskCode () {
      let options = {
        url: this.$apiUrl + "/api/services/app/Task/GetTaskCode",
        method: "GET",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          //   console.info(res);
          if (res && res.data) {
            this.form.setFieldsValue({
              TaskCode: res.data,
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getAllProject () {
      let url = this.$apiUrl + "/api/services/app/PersonnelTask/GetProjectList";
      this.$api
        .request({
          url: url,
          method: "GET",
          headers: { "Content-Type": "application/json-patch+json" },
        })
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.ProjectData.splice(0, this.ProjectData.length);
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              this.ProjectData.push({ id: item.id, name: item.projectName });
            }
          }
        })
        .catch((e) => {
          abp.message.warn(this.l("DefaultErrorMessage"));
        });
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    ProjectChange (value, e) {
      this.getAllTemplate(value);
    },
    downLoad () {
      //   window.location.href = '/门店导入模板.xls'
      fileHelper.downloadTemplateFile("执行记录与发放记录导入模板.xls");
    },
    handleChange (info) {
      this.fileList = info.fileList;
      if (info.file.status === "done") {
        const res = info.file.response;
        if (res.code === 200) {
        } else {
          this.fileList.pop();
          this.$message.error(`文件上传失败`);
        }
      } else if (info.file.status === "error") {
        this.$message.error(`文件上传失败`);
      }
    },

    //限制文件上传大小
    beforeUpload (file) {
      //   console.log(file);
      // 设置为false 手动上传
      this.importFile = file;
      return false;
    },
    workOrderChanged (target, old) {
      console.log(target);
      console.log(old);
      let targetWorkOrder = null;
      for (let i in this.workOrders) {
        let curWorkOrder = this.workOrders[i];
        if (curWorkOrder.id == target) {
          targetWorkOrder = curWorkOrder;
          break;
        }
      }
      if (targetWorkOrder != null) {
        console.log(
          "----------------------------fuck---------------------------------"
        );
        this.workOrderNo = targetWorkOrder.woNo;
        this.form.setFieldsValue({
          //Project: data.projectName,
          //DisplayCompanyName: data.displayCompanyName,
          Education: targetWorkOrder.educationalBackground,
          Experience: targetWorkOrder.handsOnBackground,
          //Needs: ,
          workOrderNo: targetWorkOrder.woNo,
          // ProjectId: targetWorkOrder.projectId,
          Salary: targetWorkOrder.price,
          SalaryType: targetWorkOrder.priceBase,
          SexRequired: targetWorkOrder.gender,
          //TaskCode: data.taskCode,
          TaskDate: [
            targetWorkOrder.beginTime.substring(0, 10),
            targetWorkOrder.endTime.substring(0, 10),
          ],
        });
        //workOrderOptions: [],
      }
    },
    getAllTemplate (projectId, callback) {
      let url =
        this.$apiUrl + "/api/services/app/Task/GetProjectTaskTemplateList";
      this.$api
        .request({
          url: url,
          method: "GET",
          params: { projectId: projectId },
          headers: { "Content-Type": "application/json-patch+json" },
        })
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.TemplateData.splice(0, this.TemplateData.length);
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              this.TemplateData.push({
                id: item.id,
                name: item.taskTemplateName,
              });
            }
          }
          if (callback && typeof callback == "function") {
            callback();
          }
        })
        .catch((e) => {
          abp.message.warn(this.l("DefaultErrorMessage"));
        });
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}

.ant-cascader-picker {
  width: 85% !important;
}

.ant-btn-primary {
  margin-left: 10px;
}

.formScroll {
  max-height: 600px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.dianmi-select {
  width: 40%;
  margin-left: 5px;
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>
