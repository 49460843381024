<template>
  <a-spin :spinning="spinning">
    <a-form :form="form" @submit="handleSubmit" layout="horizontal" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
      <a-row :gutter="24">
        <a-col span="12">
          <!-- 任务标题 -->
          <a-form-item :label="l('TaskHeaderName')">
            <a-input v-decorator="['TaskTitle']" disabled />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <!-- 任务编码 -->
          <a-form-item :label="l('TaskHeaderCode')">
            <a-input disabled v-decorator="['TaskCode']" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col span="12">
          <!-- 项目时间 -->
          <a-form-item :label="l('TaskHeaderTime')">
            <a-input disabled v-decorator="['TaskTime']" />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <!--所属项目-->
          <a-form-item :label="l('ProjectHeaderName')">
            <a-input disabled v-decorator="['ProjectName']" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col span="12">
          <!--计薪方式-->
          <a-form-item :label="l('TaskHeadSalaryType')">
            <a-select disabled v-decorator="['SalaryType']" />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <!--工价-->
          <a-form-item :label="l('TaskHeaderSalary')">
            <a-input-number :step="0.01" :min="0.01" disabled style="width: 100%" v-decorator="['Salary']" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col span="12">
          <!--学历要求-->
          <a-form-item :label="l('TaskHeadEdutation')">
            <a-input disabled v-decorator="['Education']" />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <!--经验要求-->
          <a-form-item :label="l('TaskHeadYearNeed')">
            <a-input v-decorator="['Experience']" disabled style="width: 100%"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col span="12">
          <!-- 岗位福利-->
          <a-form-item :label="l('TaskHeadWelfare')">
            <a-input v-decorator="['Welfare',]" disabled style="width: 100%">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <!--所需人数-->
          <a-form-item :label="l('TaskHeaderNeeds')">
            <a-input-number v-decorator="['Needs']" disabled :step="1" :min="1" style="width: 100%" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col span="12">
          <!--结算限价-->
          <a-form-item :label="l('结算限价')">
            <span
              style="background-color: rgb(217,217,217);height: 32px;line-height: 32px;padding:0 7px;display: inline-block;margin-right: -2px;border: 1px solid rgb(217,217,217);">最高</span>
            <a-input-number placeholder="请输入最高结算价" disabled v-decorator="['MaxPrice']" :step="0.01" :min="0"
              style="width: 72%" :precision="2" />
            <span
              style="background-color: rgb(217,217,217);height: 32px;line-height: 32px;padding:0 7px;display: inline-block;margin-right: -2px;border: 1px solid rgb(217,217,217);">元</span>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item :label="l('TaskHeadRemark')">
            <div id="TaskRemark"></div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
import E from 'editor4kg';

export default {
  mixins: [ModalComponentBase],
  name: "task-detail-pannel",
  props: ['TaskId'],
  data () {
    return {
      // 获取到的数据
      entity: {},
      spinning: false,
      PositionData: [],
      SalaryData: [],
      StudyData: [],
      ExperienceData: [],
      WelfareData: [],
      Editor: null
    };
  },
  watch: {
    TaskId: function (newVal, oldVal) {
      this.getData();
    }
  },
  components: {},
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created () {
    this.PositionData = dataItemApi.getOptions("Positions");
    this.SalaryData = dataItemApi.getOptions("SalaryType");
    this.StudyData = dataItemApi.getOptions("Education");
    this.ExperienceData = dataItemApi.getOptions("Years");
    this.WelfareData = dataItemApi.getOptions("Welfare");
  },
  mounted () {
    console.log(this);
    const editor = new E("#TaskRemark");
    editor.config.menus = []
    editor.create();
    editor.$textElem.attr('contenteditable', false);
    this.Editor = editor;
  },
  methods: {
    /**
     * 获取数据
     */
    /**
    * 获取数据
    */
    getData () {
      console.info(this.TaskId);
      if (this.TaskId && this.TaskId != '') {
        this.spinning = true;
        let options = {
          url: this.$apiUrl + '/api/services/app/Task/GetById',
          params: { id: this.TaskId },
          method: 'GET',
          headers: {
            "Content-Type": "application/json-patch+json",
          }
        };
        this.$api.request(options).then(res => {
          this.spinning = false;
          let data = res.data;
          if (data) {
            this.form.setFieldsValue({
              DisplayCompanyName: data.displayCompanyName,
              Education: data.education,
              Experience: data.experienceRequest,
              Needs: data.numberOfNeed,
              ProjectName: data.projectName,
              Salary: data.price,
              SalaryType: data.denominated,
              SexRequired: data.sexRequest,
              TaskCode: data.taskCode,
              TaskTime: data.startTime.substring(0, 10) + "至" + data.endTime.substring(0, 10),
              TaskTitle: data.taskTitle,
              Welfare: data.postWelfare,
              MaxPrice: data.maxPrice
            });
            console.info(data.content);
            if (data.content) {
              this.Editor.txt.html(decodeURIComponent(data.content));
            }
          }
        }).catch(e => {
          this.spinning = false;
          console.error(e);
        });
      }
    },
    /**
     * 提交表单
     */
    handleSubmit (e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.spinning = true;
          var dto = new ProjectDto();
          dto = dto.formJson(values);
          if (this.entity && this.entity.id) {
            dto.setId(this.entity.id);
          }
          this.Proxy.CreateOrUpdateProject(dto)
            .finally(() => {
              this.spinning = false;
            })
            .then((res) => {
              this.$notification["success"]({
                message: this.l("SavedSuccessfully"),
              });
              this.success(true);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
