<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>执行记录导入</span>
      </div>
    </div>
    <a-form layout="horizontal">
      <a-form-item
        label="是否生成帐单"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-tooltip placement="top">
          <template slot="title">
            <span>打开后会自动生成帐单</span>
          </template>
          <a-switch v-model="isCreateBill" />
        </a-tooltip>
      </a-form-item>
      <a-form-item
        label="执行记录文件"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-tooltip placement="top">
          <template slot="title">
            <span>只支持.xls,.xlsx文件格式上传</span>
          </template>
          <a-upload
            ref="uploadExcel"
            name="file"
            :multiple="false"
            accept=".xls,.xlsx"
            :headers="uploadHeaders"
            :before-upload="beforeUpload"
            :showUploadList="showUploadList"
            @change="handleChange"
          >
            <a-button> <a-icon type="upload" />选择文件</a-button>
          </a-upload>
        </a-tooltip>
      </a-form-item>
    </a-form>
    <div style="text-align: right; margin-top: 40px">
      <a-tooltip placement="top">
        <template slot="title">
          <span>下载模板填充后进行导入</span>
        </template>
        <a-button @click="downLoad" style="margin-right: 10px">
          <a-icon type="download" />
          <span>下载模板</span>
        </a-button>
      </a-tooltip>
      <a-button
        type="primary"
        @click="handleUpload"
        :disabled="fileList.length === 0"
      >
        <a-icon type="import" />
        <span>立即导入</span>
      </a-button>
    </div>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { AppConsts } from "@/abpPro/AppConsts";
import fileHelper from "../../../shared/helpers/fileHelper";
import taskApi from "@/shared/service-proxies/task/services/task-proxies";

import { fileDownloadService } from "@/shared/utils";

export default {
  mixins: [ModalComponentBase],
  name: "task-executed-record-import-form",
  data() {
    return {
      isCreateBill: false,
      taskId: "",
      spinning: false,
      fileList: [],
      uploadHeaders: {},
      uploadUrl: "",
      showUploadList: true,
    };
  },
  components: {},
  created() {
    Object.assign(this.uploadHeaders, {
      Authorization: "Bearer " + abp.auth.getToken(),
    });
    this.fullData();
    this.uploadUrl =
      AppConsts.remoteServiceBaseUrl +
      "/api/services/app/Task/ImportTaskExecutedRecordMax?taskId=" +
      this.taskId;
  },
  mounted() {},
  methods: {
    async handleChange(info) {
      console.log("info:", info);
      // this.fileList = info.fileList;
      const status = info.file.status;
      this.fileList = status === "removed" ? [] : [info.file];
      // if (status !== "uploading") {
      //   // console.log(info.file, info.fileList);
      // }
      // if (status == "error") {
      //   let message = info.file.response.error.message;
      //   this.$message.error(message);
      //   this.spinning = false;
      // }
      // if (status === "done") {
      //   console.log(info);
      //   this.spinning = false;
      //   if (!info.file.response.success) {
      //     this.$message.error(info.file.response.message);
      //     return;
      //   }

      //   const res = info.file.response.result;

      //   if (
      //     res.fileInfo &&
      //     res.fileInfo.fileName &&
      //     res.fileInfo.fileToken &&
      //     res.fileInfo.fileType
      //   ) {
      //     this.$message.warning(res.message);
      //     fileDownloadService.downloadTempFile(res.fileInfo);
      //   } else {
      //     this.$message.success(`${info.file.name}导入成功.`);
      //     this.showUploadList = true;
      //     this.success(true);
      //   }
      // }
    },
    // 上传
    async handleUpload() {
      if (this.fileList.length === 0) {
        this.$message.error("请选择文件进行导入");
        return;
      }
      const file = this.fileList[0];
      const url = `/api/services/app/Task/ImportTaskExecutedRecordMax?taskId=${this.taskId}&isCreateBill=${this.isCreateBill}`;
      var data = new FormData();
      data.append("file", file);
      this.spinning = true;
      let res = await taskApi.importTaskExecutedRecordNew(data, url);
      this.spinning = false;
      console.log(res);
      if (
        res.fileInfo &&
        res.fileInfo.fileName &&
        res.fileInfo.fileToken &&
        res.fileInfo.fileType
      ) {
        this.$message.warning(res.message);
        fileDownloadService.downloadTempFile(res.fileInfo);
      } else {
        if (res.success || res.fileInfo === null) {
          this.$message.success(`${file.name}导入成功.`);
          this.success(true);
        }
      }
    },
    //限制文件上传大小
    beforeUpload(file) {
      var fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isFile = !(fileName != "xlsx" && fileName != "xls");

      if (!isFile) {
        this.$message.error("文件格式必须是xls或xlsx!");
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 <= 10;
      if (!isLt10M) {
        this.$message.error("上传的文件必须小于 10MB！");
        return false;
      }
      // this.fileList = [file];
      // if (isFile && isLt10M) {
      //   this.spinning = true;
      // }
      // 手动上传
      return false; //isFile && isLt10M;
    },
    //点击下载模板
    downLoad() {
      //   window.location.href = '/门店导入模板.xls'
      fileHelper.downloadTemplateFile("执行记录与发放记录导入模板.xls");
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin: 0 20px !important;
}
.upload--container {
  margin: 20px !important;
}
</style>
